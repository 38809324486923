import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React, { useContext, useEffect, useState } from "react";
import { ProcessGrapqlError } from "..";
import { Context } from "../store";
import { AlertDialog, HalfScreenDialog, UpdateUsers } from "../_components";
import { AlertHelper, LoaderHelper } from "../_helpers";
import Pagination from "@material-ui/lab/Pagination";
import { DELETE_STAFF, GET_STAFFS } from "../_queries";
import { Colors } from "../_constants";
import { useUser } from "../hooks";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  bredcrumb: {
    backgroundColor: `${Colors.WHITE}`,
    marginBottom: "15px",
    borderRadius: "5px",
  },
  bredcrumbTitle: {
    fontSize: "16px",
    fontWeight: "600",
  },
  tableHeader: {
    backgroundColor: `${Colors.SECONDARY_LIGHT}`,
    padding: "15px",
  },
}));
export const Users = () => {
  const classes = useStyles();
  const { state, setGlobalState } = useContext(Context);
  const [deleting, setDeleting] = useState(false);
  const [ref_id, setRefId] = useState("0");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const Delete = (id) => {
    setRefId(id);
    setDeleting(true);
  };
  const Negative = () => {
    setRefId("0");
    setDeleting(false);
  };
  const Positive = () => {
    setDeleting(false);
    AlertHelper.progress("Deleting...");
  };

  const DataLists = () => {
    const { GetUsers, UserListLoading, UsersList } = useUser();
    if (UserListLoading) {
      LoaderHelper.load();
    } else {
      LoaderHelper.clear();
    }

    useEffect(() => {
      GetUsers({ variables: { perPage: rowsPerPage, pageNo: page } });
      // eslint-disable-next-line
    }, [rowsPerPage, page]);

    const changeUserList = (event, value) => {
      setPage(value);
    };

    return (
      <>
        <List className={classes.bredcrumb}>
          <ListItem>
            <ListItemText
              primary={
                <span className={classes.bredcrumbTitle}>Users list</span>
              }
            />
            <ListItemSecondaryAction>
              {/* <Button
                startIcon={<AddBoxIcon />}
                onClick={() => {
                  setGlobalState({
                    ...state,
                    staff_adding: true,
                    editing_staff: false,
                  });
                }}
                variant={"text"}
                color={"primary"}
              >
                Add New
              </Button> */}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                {/* <TableCell className={classes.tableHeader}>User ID</TableCell> */}
                <TableCell className={classes.tableHeader}>Username</TableCell>
                <TableCell className={classes.tableHeader}>Email</TableCell>
                <TableCell className={classes.tableHeader}>Country</TableCell>
                <TableCell className={classes.tableHeader}>
                  Created ON
                </TableCell>
                <TableCell className={classes.tableHeader}>Action</TableCell>
                <TableCell className={classes.tableHeader}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!UserListLoading &&
                Object.keys(UsersList).length > 0 &&
                UsersList.userlist.map((row) => (
                  <TableRow key={row.user_id}>
                    {/* <TableCell component="th" scope="row">
                      {row.user_id}
                    </TableCell> */}
                    <TableCell component="th" scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.country}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.created_on}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <IconButton
                        onClick={() => {
                          setGlobalState({
                            ...state,
                            edit_staff: row,
                            editing_staff: true,
                            staff_adding: true,
                          });
                        }}
                        aria-label="delete"
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        onClick={() => Delete(row.user_id)}
                        aria-label="delete"
                      >
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={UsersList && UsersList.count}
          variant="outlined"
          color="primary"
          onChange={changeUserList}
          page={page}
          style={{ float: "right", margin: "5px" }}
        />
      </>
    );
  };
  return (
    <>
      {deleting && (
        <AlertDialog
          open={true}
          negative={Negative}
          positive={Positive}
          title={"Warning"}
          description={"Are you sure you want to delete?"}
        />
      )}
      {state.staff_adding && (
        <HalfScreenDialog
          open={true}
          component={<UpdateUsers />}
          CloseCallback={() => {
            setGlobalState({
              ...state,
              staff_adding: false,
            });
          }}
        />
      )}
      <Container maxWidth={false}>
        <Box className={classes.container}>
          <DataLists />
        </Box>
      </Container>
    </>
  );
};
