import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textStyle: {
    textAlign: "center",
    padding: "20px",
    color: "red",
  },
}));

export function NotAccess() {
  const classes = useStyles();
  return (
    <div>
      <h3 className={classes.textStyle}>Sorry, you don't have access</h3>
    </div>
  );
}
