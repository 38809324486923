import {
  Box,
  Button,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import * as Yup from "yup";
import { CustomSelectField, CustomTextField } from "..";
import { ProcessGrapqlError } from "../..";
import { useSweepStack, useRouter } from "../../hooks";
import { Context } from "../../store";
import { AlertHelper, LoaderHelper } from "../../_helpers";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  chip_paper: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const UpdateRouter = () => {
  const classes = useStyles();
  const { state, setGlobalState } = useContext(Context);
  const { useGetSweepStacks } = useSweepStack();
  const { AddRouterDetails, GetRouter, UpdateRouterDetails } = useRouter();
  const [GetSweepstacks, { loading, error, data }] = useGetSweepStacks;
  const uuid = uuidv4();
  const { editing_router: editing = false, edit_router: editData = {} } = state;
  function reset() {
    setGlobalState({
      ...state,
      edit_router: {},
      editing_router: false,
      router_adding: false,
    });
  }

  useEffect(() => {
    GetSweepstacks();
  }, [GetSweepstacks]);
  if (loading) {
    LoaderHelper.load();
  } else {
    LoaderHelper.clear();
  }
  if (error) {
    ProcessGrapqlError(error);
  }

  return (
    <>
      <Formik
        initialValues={{
          id: `${editing ? editData.router_id : ""}`,
          name: `${editing ? editData.router_name : ""}`,
          route: `${
            editing ? editData.router_link : `https://elspur.com/router/${uuid}`
          }`,
          endDateTime: `${editing ? editData.router_end_date_time : ""}`,
          sweepstakes: `${editing ? editData.sweepstake_title : ""}`,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required("Router Name is required"),
          route: Yup.string().max(255).required("Link is required"),
          endDateTime: Yup.date().required("End date time is required"),
        })}
        onSubmit={async (payload) => {
          if (editing) {
            AlertHelper.progress("Updating...");
            UpdateRouterDetails({
              variables: {
                name: payload.name,
                route: payload.route,
                end_date_time: payload.endDateTime,
                sweepstake_title: payload.sweepstakes,
                router_id: editData.router_id,
              },
              onCompleted() {
                AlertHelper.success("Successfully Updated!");
                GetSweepstacks();
                reset();
              },
              onError(error) {
                ProcessGrapqlError(error);
                GetSweepstacks();
              },
              update(cache) {
                GetRouter();
              },
            });
          } else {
            AlertHelper.progress("Creating...");
            AddRouterDetails({
              variables: {
                router_id: uuid,
                name: payload.name,
                route: payload.route,
                end_date_time: payload.endDateTime,
                sweepstake_title: payload.sweepstakes,
              },
              onCompleted() {
                AlertHelper.success("Successfully Added!");
                GetSweepstacks();
                reset();
              },
              onError(error) {
                ProcessGrapqlError(error);
                GetSweepstacks();
              },
              update(cache) {
                GetRouter();
              },
            });
          }
          reset();
          return false;
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <Typography color="textPrimary" variant="h2">
                Create new Router
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                All fields are mandetory
              </Typography>
            </Box>
            <Grid container spacing={3} direction="row">
              <Grid item md={6}>
                <CustomTextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Router Name"
                  margin="dense"
                  placeholder="Enter Router Name"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <CustomTextField
                  error={Boolean(touched.route && errors.route)}
                  fullWidth
                  helperText={touched.route && errors.route}
                  label="Router Link"
                  margin="dense"
                  name="route"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.route}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid item md={6}>
                <CustomTextField
                  error={Boolean(touched.endDateTime && errors.endDateTime)}
                  fullWidth
                  helperText={touched.loendDateTimego && errors.endDateTime}
                  type="datetime-local"
                  label="End Date time"
                  margin="dense"
                  placeholder="Select Date time"
                  name="endDateTime"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.endDateTime}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <CustomSelectField
                  select
                  error={Boolean(touched.sweepstakes && errors.sweepstakes)}
                  fullWidth
                  helperText={touched.sweepstakes && errors.sweepstakes}
                  label="Sweepstack"
                  margin="dense"
                  name="sweepstakes"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.sweepstakes}
                >
                  {data &&
                    data.GetSweepStacks.map((sweepstack) => {
                      return (
                        <MenuItem
                          key={sweepstack.title}
                          value={`${sweepstack.title}`}
                        >
                          {sweepstack.title}
                        </MenuItem>
                      );
                    })}
                </CustomSelectField>
              </Grid>
            </Grid>
            <Box className={classes.button}>
              <Button
                onClick={() => {
                  reset();
                }}
                variant="contained"
                color="default"
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {editing ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};
