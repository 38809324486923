import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { history } from "./_helpers";
import reportWebVitals from "./reportWebVitals";
import { useGlobalState, Context } from "./store";

import {
  ApolloClient,
  ApolloProvider,
  split,
  InMemoryCache,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { onError } from "apollo-link-error";
import createUploadLink from "apollo-upload-client/public/createUploadLink.js";
import { AlertHelper } from "./_helpers";
import { WebSocketLink } from "@apollo/client/link/ws";
const mainLink = new createUploadLink({
  // uri: "https://5yh9h2ng1b.execute-api.ap-south-1.amazonaws.com/dev/graphql",
  uri: "https://api.elspur.com/graphql",
 // uri: "http://localhost:4001/graphql",
});

const wsLink = new WebSocketLink({
  //uri: "wss://5yh9h2ng1b.execute-api.ap-south-1.amazonaws.com/dev/graphql",
  uri: "wss://api.elspur.com/graphql",
  options: {
    reconnect: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  mainLink
);
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      AlertHelper.error(message);
    });
  if (networkError) console.log(networkError);
});
const client = new ApolloClient({
  link: authLink.concat(errorLink.concat(splitLink)), // <= otherwise will send to this
  cache: new InMemoryCache(),
});

export const ProcessGrapqlError = ({
  graphQLErrors,
  networkError,
  ...rest
}) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(message);
    });
  if (rest) {
    console.log(rest);
  }
};
const Index = () => {
  const store = useGlobalState();
  return (
    <ApolloProvider client={client}>
      <Context.Provider value={store}>
        <BrowserRouter history={history}>
          <App />
        </BrowserRouter>
      </Context.Provider>
    </ApolloProvider>
  );
};
ReactDOM.render(<Index />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
