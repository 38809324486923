import { gql } from "@apollo/client";

export const GET_SURVEY = gql`
  query GetSurvey($page_no: Int!, $per_page: Int!) {
    Survey: GetSurvey(page_no: $page_no, per_page: $per_page) {
      survey {
        survey_id
        survey_name
        length
        start_date
        expire_on
        coin
        chip
        url
        rules
        thumb_url
        is_featured
        country
      }
      count
    }
  }
`;

export const ADD_SURVEY = gql`
  mutation AddSurvey(
    $survey_id: String!
    $survey_name: String!
    $length: String
    $start_date: String
    $expire_on: String
    $coin: String
    $chip: String
    $url: String
    $rules: JSON
    $thumb_url: String
    $is_featured: Boolean
    $country: String
    $reputation: Int
    $scheduleDatetime: String
    $total: Int
  ) {
    AddSurvey(
      survey_id: $survey_id
      survey_name: $survey_name
      length: $length
      start_date: $start_date
      expire_on: $expire_on
      coin: $coin
      chip: $chip
      url: $url
      rules: $rules
      thumb_url: $thumb_url
      is_featured: $is_featured
      country: $country
      reputation: $reputation
      schedule_datetime: $scheduleDatetime
      total: $total
    ) {
      survey_id
      survey_name
      length
      start_date
      expire_on
      coin
      chip
      url
      rules
      thumb_url
      is_featured
      country
      total
      schedule_datetime
      reputation
    }
  }
`;

export const UPDATE_SURVEY = gql`
  mutation UpdateSurvey(
    $survey_id: String!
    $survey_name: String
    $length: String
    $start_date: String
    $expire_on: String
    $coin: String
    $chip: String
    $url: String
    $rules: JSON
    $rules_info: JSON
    $thumb_url: String
    $is_featured: Boolean
    $country: String
    $reputation: Int
    $feasibility: Int
    $scheduleDatetime: String
    $total: Int
    $active: Boolean
  ) {
    UpdateSurvey(
      survey_id: $survey_id
      survey_name: $survey_name
      length: $length
      start_date: $start_date
      expire_on: $expire_on
      coin: $coin
      chip: $chip
      url: $url
      rules: $rules
      thumb_url: $thumb_url
      is_featured: $is_featured
      country: $country
      reputation: $reputation
      schedule_datetime: $scheduleDatetime
      total: $total
      active: $active
      feasibility: $feasibility
      rules_info: $rules_info
    ) {
      survey_id
      survey_name
      length
      start_date
      expire_on
      coin
      chip
      url
      rules
      thumb_url
      is_featured
      country
      total
      schedule_datetime
      reputation
    }
  }
`;

export const DELETE_SURVEY = gql`
  mutation DeleteSurvey($survey_id: String!) {
    DeleteSurvey(survey_id: $survey_id) {
      survey_id
    }
  }
`;

export const GET_RULES_BY_SURVEY = gql`
  query GetRulesBySurvey($survey_id: String!) {
    GetRulesBySurvey(survey_id: $survey_id) {
      rules
    }
  }
`;

export const GET_FEASIBILITY = gql`
  query GetFeasibility($rules: JSON!) {
    GetFeasibility(rules: $rules) {
      user
      rules
    }
  }
`;

export const GET_SURVEY_ID = gql`
  query {
    GetSurveyID {
      survey_Id
    }
  }
`;

export const GET_SURVEY_BY_ID = gql`
  query GetSurveyById($survey_id: String!) {
    GetSurveyById(survey_id: $survey_id) {
      survey_id
      survey_name
      length
      start_date
      expire_on
      coin
      chip
      url
      rules
      rules_info
      thumb_url
      is_featured
      country
      total
      schedule_datetime
      reputation
      feasibility
      active
    }
  }
`;
