import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React, { useContext, useState } from "react";
import { ProcessGrapqlError } from "..";
import { Context } from "../store";
import { AlertDialog, CustomModal } from "../_components";
import { AlertHelper, LoaderHelper } from "../_helpers";
import { GET_BLOGS,DELETE_BLOG } from "../hooks";
import { Colors } from "../_constants";
import { UpdateBlog } from "../_components/blog/UpdateBlog";



const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  bredcrumb: {
    backgroundColor: `${Colors.WHITE}`,
    marginBottom: "15px",
    borderRadius: "5px",
  },
  bredcrumbTitle: {
    fontSize: "16px",
    fontWeight: "600",
  },
  tableHeader: {
    backgroundColor: `${Colors.SECONDARY_LIGHT}`,
    padding: "15px",
  },
}));
export const Blogs = () => {
  const classes = useStyles();
  const { state, setGlobalState } = useContext(Context);
  const [deleting, setDeleting] = useState(false);
  const [ref_id, setRefId] = useState("0");
  const [DeleteBlog] = useMutation(DELETE_BLOG, {
    onCompleted() {
      AlertHelper.success("Successfully deleted!");
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
  });
  const Delete = (id) => {
    setRefId(id);
    setDeleting(true);
  };
  const Negative = () => {
    setRefId("0");
    setDeleting(false);
  };
  const Positive = () => {
    setDeleting(false);
    AlertHelper.progress("Deleting...");
    DeleteBlog({
      variables: { id: ref_id },
      update(cache) {
        const existing = cache.readQuery({ query: GET_BLOGS });
        const newBlogs = existing.Blogs.filter(
          (item) => item.id !== ref_id
        );
        cache.writeQuery({
          query: GET_BLOGS,
          data: { Blogs: newBlogs },
        });
      },
    });
  };

  const DataLists = () => {
    const { loading, error, data } = useQuery(GET_BLOGS);
    if (loading) {
      LoaderHelper.load();
    } else {
      LoaderHelper.clear();
    }
    if (error) {
      ProcessGrapqlError(error);
    }

    return (
      <>
        <List className={classes.bredcrumb}>
          <ListItem>
            <ListItemText
              primary={
                <span className={classes.bredcrumbTitle}>Blog List</span>
              }
              secondary="Add blog here"
            />
            <ListItemSecondaryAction>
              <Button
                startIcon={<AddBoxIcon />}
                onClick={() => {
                  setGlobalState({
                    ...state,
                    blog_adding: true,
                    editing_blog: false,
                  });
                }}
                variant={"text"}
                color={"primary"}
              >
                Add New
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>Blog</TableCell>
                <TableCell className={classes.tableHeader}>Action</TableCell>
                <TableCell className={classes.tableHeader}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.Blog.map((blog) => (
                  <TableRow key={blog.id}>
                    <TableCell component="th" scope="row">
                      {blog.title}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        onClick={() => {
                          setGlobalState({
                            ...state,
                            edit_blog: blog,
                            editing_blog: true,
                            blog_adding: true,
                          });
                        }}
                        aria-label="delete"
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        onClick={() => Delete(blog.id)}
                        aria-label="delete"
                      >
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };
  return (
    <>
      {deleting && (
        <AlertDialog
          open={true}
          negative={Negative}
          positive={Positive}
          title={"Warning"}
          description={"Are you sure you want to delete?"}
        />
      )}
      {state.blog_adding && (
        <CustomModal
          open={true}
          component={<UpdateBlog  CloseCallback={() => {
            setGlobalState({
              ...state,
              blog_adding: false,
            });
          }}/>}
          CloseCallback={() => {
            setGlobalState({
              ...state,
              blog_adding: false,
            });
          }}
        />
      )}
      <Container maxWidth={false}>
        <Box className={classes.container}>
          <DataLists />
        </Box>
      </Container>
    </>
  );
};
