import React from "react";
import { Navigate } from "react-router-dom";
import {
  Login,
  NotFound,
  ProfileSetUp,
  Role,
  Staff,
  Settings,
  SurveyList,
  Users,
  SweepStacks,
  Blogs,
  Router
} from "../views";
import { DashboardLayout, MainLayout } from "../_components";
import { URLS } from "../_constants";
const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      { path: URLS.ROLE, element: <Role /> },
      { path: URLS.STAFF, element: <Staff /> },
      { path: URLS.PROFILE, element: <ProfileSetUp /> },
      { path: URLS.SETTINGS, element: <Settings /> },
      { path: URLS.SURVEY, element: <SurveyList /> },
      { path: URLS.USERS, element: <Users /> },
      { path: URLS.SWEEPSTACK, element: <SweepStacks /> },
      { path: URLS.BLOG, element: <Blogs /> },
      { path: URLS.ROUTER, element: <Router /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: URLS.LOGIN, element: <Login /> },
      { path: "404", element: <NotFound /> },
      { path: "/", element: <Navigate to={`${URLS.LOGIN}`} /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
