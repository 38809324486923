import { gql } from "@apollo/client";

export const ADD_CONFIG = gql`
  mutation Mutation(
    $config: String!
    $name: String!
    $code: String!
    $value: String!
  ) {
    AddConfig(
      config: $config
      name: $name
      code: $code
      value: $value
    ) {
      config_id
      config
      code
      name
      value
    }
  }
`;

export const UPDATE_CONFIG = gql`
  mutation UpdateConfig(
    $configId: String!
    $config: String
    $name: String
    $code: String
    $value: String
  ) {
    UpdateConfig(
      config_id: $configId
      config: $config
      name: $name
      code: $code
      value: $value
    ) {
      config_id
      config
      name
      code
      value
    }
  }
`;

export const DELETE_CONFIG = gql`
  mutation DeleteConfig($configId: String!) {
    DeleteConfig(config_id: $configId) {
      config_id
    }
  }
`;


export const GET_CONFIGS = gql`
  query GetConfig($config: String!) {
    GetConfig(config: $config) {
      config_id
      config
      code
      name
      value
    }
  }
`;

export const GET_CONFIG = gql`
  query GetConfigByCode($code: String!) {
    GetConfigByCode(code: $code) {
      config_id
      config
      code
      name
      value
    }
  }
`;

export const GET_ALL_CONFIG = gql`
  query {
    GetAllConfig {
      config
    }
  }
`;
