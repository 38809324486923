import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { useRoutes } from "react-router-dom";
import theme from "../theme";
import { CustomFullLoader, GlobalStyles, CustomSnackBar } from "../_components";
import routes from "./routes";

export const App = () => {
  const routing = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <CustomFullLoader />
      <CustomSnackBar />
      {routing}
    </ThemeProvider>
  );
};
