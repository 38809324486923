import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React, { useContext, useEffect, useState } from "react";
import { ProcessGrapqlError } from "..";
import { Context } from "../store";
import {
  AlertDialog,
  HalfScreenDialog,
  UpdateStaff,
  UpdateSweepstack,
} from "../_components";
import { AlertHelper, LoaderHelper } from "../_helpers";
import { DELETE_STAFF, GET_STAFFS } from "../_queries";
import { Colors } from "../_constants";
import { GET_SWEEPSTACKS, useSweepStack } from "../hooks";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  bredcrumb: {
    backgroundColor: `${Colors.WHITE}`,
    marginBottom: "15px",
    borderRadius: "5px",
  },
  bredcrumbTitle: {
    fontSize: "16px",
    fontWeight: "600",
  },
  tableHeader: {
    backgroundColor: `${Colors.SECONDARY_LIGHT}`,
    padding: "15px",
  },
}));
export const SweepStacks = () => {
  const classes = useStyles();
  const { state, setGlobalState } = useContext(Context);
  const [deleting, setDeleting] = useState(false);
  const [ref_id, setRefId] = useState("0");

  const { useDeleteSweepStack } = useSweepStack();

  const [DeleteSweepStack] = useDeleteSweepStack;

  const Delete = (id) => {
    setRefId(id);
    setDeleting(true);
  };
  const Negative = () => {
    setRefId("0");
    setDeleting(false);
  };
  const Positive = () => {
    setDeleting(false);
    AlertHelper.progress("Deleting...");

    DeleteSweepStack({
      variables: { uuid: ref_id },
      update(cache) {
        const existing = cache.readQuery({ query: GET_SWEEPSTACKS });
        const newStack = existing.GetSweepStacks.filter(
          (item) => item.uuid !== ref_id
        );
        cache.writeQuery({
          query: GET_SWEEPSTACKS,
          data: { GetSweepStacks: newStack },
        });
      },
    });
  };

  const DataLists = () => {
    const { useGetSweepStacks } = useSweepStack();
    const [GetSweepstacks, { loading, error, data }] = useGetSweepStacks;
    useEffect(() => {
      GetSweepstacks();
    }, [GetSweepstacks]);
    if (loading) {
      LoaderHelper.load();
    } else {
      LoaderHelper.clear();
    }
    if (error) {
      ProcessGrapqlError(error);
    }

    return (
      <>
        <List className={classes.bredcrumb}>
          <ListItem>
            <ListItemText
              primary={
                <span className={classes.bredcrumbTitle}>Sweepstacks list</span>
              }
              secondary="Add Sweepstacks here"
            />
            <ListItemSecondaryAction>
              <Button
                startIcon={<AddBoxIcon />}
                onClick={() => {
                  setGlobalState({
                    ...state,
                    sweep_adding: true,
                    editing_sweep: false,
                  });
                }}
                variant={"text"}
                color={"primary"}
              >
                Add New
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>Title</TableCell>
                <TableCell className={classes.tableHeader}>Details</TableCell>
                <TableCell className={classes.tableHeader}>Logo</TableCell>
                <TableCell className={classes.tableHeader}>Type</TableCell>
                <TableCell className={classes.tableHeader}>Status</TableCell>
                <TableCell className={classes.tableHeader}>
                  Start Date
                </TableCell>
                <TableCell className={classes.tableHeader}>End Date</TableCell>
                <TableCell className={classes.tableHeader}>Action</TableCell>
                <TableCell className={classes.tableHeader}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.GetSweepStacks.map((row) => (
                  <TableRow key={row.uuid}>
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.details}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <img style={{ width: "100px" }} src={row.logo} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.type}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.status}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.start_date_time}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.end_date_time}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        onClick={() => {
                          setGlobalState({
                            ...state,
                            edit_sweep: row,
                            editing_sweep: true,
                            sweep_adding: true,
                          });
                        }}
                        aria-label="delete"
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        onClick={() => Delete(row.uuid)}
                        aria-label="delete"
                      >
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };
  return (
    <>
      {deleting && (
        <AlertDialog
          open={true}
          negative={Negative}
          positive={Positive}
          title={"Warning"}
          description={"Are you sure you want to delete?"}
        />
      )}
      {state.sweep_adding && (
        <HalfScreenDialog
          open={true}
          component={<UpdateSweepstack />}
          CloseCallback={() => {
            setGlobalState({
              ...state,
              sweep_adding: false,
            });
          }}
        />
      )}
      <Container maxWidth={false}>
        <Box className={classes.container}>
          <DataLists />
        </Box>
      </Container>
    </>
  );
};
