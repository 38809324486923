import { gql, useLazyQuery, useMutation } from "@apollo/client";

const ADD_SWEEPSTACK = gql`
  mutation AddSweepStack(
    $details: String
    $endDateTime: String
    $logo: String
    $startDateTime: String
    $status: String
    $title: String
    $type: String
    $uuid: String
  ) {
    AddSweepStack(
      details: $details
      end_date_time: $endDateTime
      logo: $logo
      start_date_time: $startDateTime
      status: $status
      title: $title
      type: $type
      uuid: $uuid
    ) {
      details
      end_date_time
      logo
      start_date_time
      status
      title
      type
      uuid
    }
  }
`;
const UPDATE_SWEEPSTACK = gql`
  mutation UpdateSweepStack(
    $details: String
    $endDateTime: String
    $logo: String
    $startDateTime: String
    $status: String
    $title: String
    $type: String
    $uuid: String
  ) {
    UpdateSweepStack(
      details: $details
      end_date_time: $endDateTime
      logo: $logo
      start_date_time: $startDateTime
      status: $status
      title: $title
      type: $type
      uuid: $uuid
    ) {
      details
      end_date_time
      logo
      start_date_time
      status
      title
      type
      uuid
    }
  }
`;
const DELETE_SWEEPSTACK = gql`
  mutation DeleteSweepStack($uuid: String!) {
    DeleteSweepStack(uuid: $uuid) {
      details
      end_date_time
      logo
      start_date_time
      status
      title
      type
      uuid
    }
  }
`;

export const GET_SWEEPSTACKS = gql`
  query Query {
    GetSweepStacks {
      details
      end_date_time
      logo
      start_date_time
      status
      title
      type
      uuid
    }
  }
`;

export const useSweepStack = () => {
  const useAddSweepStack = useMutation(ADD_SWEEPSTACK);
  const useUpdateSweepStack = useMutation(UPDATE_SWEEPSTACK);
  const useDeleteSweepStack = useMutation(DELETE_SWEEPSTACK);
  const useGetSweepStacks = useLazyQuery(GET_SWEEPSTACKS);

  return {
    useAddSweepStack,
    useUpdateSweepStack,
    useDeleteSweepStack,
    useGetSweepStacks,
  };
};
