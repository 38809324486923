import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";
import { CustomDropDown, CustomSelectField, CustomTextField } from "..";
import { ProcessGrapqlError } from "../..";
import { Context } from "../../store";
import { AlertHelper } from "../../_helpers";
import {
  ADD_STAFF,
  GET_ROLE_DROP_DOWN,
  GET_STAFFS,
  UPDATE_STAFF,
} from "../../_queries";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export const UpdateStaff = () => {
  const classes = useStyles();
  const { state, setGlobalState } = useContext(Context);
  const [AddStaff] = useMutation(ADD_STAFF, {
    onCompleted() {
      AlertHelper.success("Successfully added!");
      reset();
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddStaff } }) {
      const existing = cache.readQuery({ query: GET_STAFFS });
      const newData = [...existing.Staffs, AddStaff];
      cache.writeQuery({
        query: GET_STAFFS,
        data: { Staffs: newData },
      });
    },
  });

  const [UpdateStaff] = useMutation(UPDATE_STAFF, {
    onCompleted() {
      AlertHelper.success("Successfully updated!");
      reset();
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateStaff } }) {
      const existing = cache.readQuery({ query: GET_STAFFS });
      const newData = existing.Staffs.map((item) =>
        item.user_id !== UpdateStaff.user_id ? item : UpdateStaff
      );
      cache.writeQuery({
        query: GET_STAFFS,
        data: { Staffs: newData },
      });
    },
  });
  const { editing_staff: editing = false, edit_staff: editData = {} } = state;
  function reset() {
    setGlobalState({
      ...state,
      edit_staff: {},
      editing_staff: false,
      staff_adding: false,
    });
  }
  return (
    <Formik
      initialValues={{
        fullname: `${editing ? editData.fullname : ""}`,
        email: `${editing ? editData.email : ""}`,
        mobile: `${editing ? editData.mobile : ""}`,
        role_id: `${editing ? editData.role_id : ""}`,
      }}
      validationSchema={Yup.object().shape({
        fullname: Yup.string().max(255).required("Name is required"),
        email: Yup.string()
          .max(255)
          .email("Enter a valid email")
          .required("Email is required"),
        mobile: Yup.string()
          .max(12)
          .min(10)
          .required("Enter a valid mobile number"),
        role_id: Yup.string().max(255).required("Select a role"),
      })}
      onSubmit={(payload) => {
        if (editing) {
          AlertHelper.progress("Updating...");
          payload["user_id"] = editData.user_id;
          UpdateStaff({ variables: payload });
        } else {
          AlertHelper.progress("Creating...");
          AddStaff({ variables: payload });
        }
        reset();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography color="textPrimary" variant="h2">
              Create new Staff
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              All fields are mandetory
            </Typography>
          </Box>
          <Grid container spacing={3} direction="row">
            <Grid item md={6}>
              <CustomTextField
                error={Boolean(touched.fullname && errors.fullname)}
                fullWidth
                helperText={touched.fullname && errors.fullname}
                label="Staff name"
                margin="dense"
                placeholder="Enter staff fullname"
                name="fullname"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fullname}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                disabled={editing}
                helperText={touched.email && errors.email}
                label="Email"
                margin="dense"
                placeholder="Enter staff email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                error={Boolean(touched.mobile && errors.mobile)}
                fullWidth
                helperText={touched.mobile && errors.mobile}
                disabled={editing}
                label="Mobile"
                margin="dense"
                placeholder="Enter a valid mobile number"
                name="mobile"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mobile}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6}>
              <CustomDropDown
                error={Boolean(touched.role_id && errors.role_id)}
                fullWidth
                helperText={touched.role_id && errors.role_id}
                label="Role"
                margin="dense"
                name="role_id"
                query={GET_ROLE_DROP_DOWN}
                objname={"RoleDropDown"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.role_id}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Box className={classes.button}>
            <Button
              onClick={() => {
                reset();
              }}
              variant="contained"
              color="default"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
                {
                  editing ? "Update" : "Save"
                }
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
