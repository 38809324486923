import { gql } from "@apollo/client";

export const GET_ROUTER_DETAILS = gql`
  query {
    GetRouterDetails {
      router_id
      router_name
      router_link
      router_end_date_time
      sweepstake_title
      sweepstake_type
      user_count
    }
  }
`;

export const ADD_ROUTER = gql`
  mutation AddRouter(
    $name: String!
    $route: String!
    $end_date_time: String!
    $sweepstake_title: String
    $router_id: ID!
  ) {
    AddRouter(
      name: $name
      route: $route
      end_date_time: $end_date_time
      sweepstake_title: $sweepstake_title
      router_id: $router_id
    ) {
      router_id
      name
    }
  }
`;

export const UPDATE_ROUTER = gql`
  mutation UpdateRouter(
    $name: String!
    $route: String!
    $end_date_time: String!
    $sweepstake_title: String
    $router_id: String!
  ) {
    UpdateRouter(
      name: $name
      route: $route
      end_date_time: $end_date_time
      sweepstake_title: $sweepstake_title
      router_id: $router_id
    ) {
      router_id
      name
    }
  }
`;

export const DELETE_ROUTER = gql`
  mutation DeleteRouter($router_id: String!) {
    DeleteRouter(router_id: $router_id)
  }
`;
