import React from "react";
import { Form } from "react-bootstrap";

export const CustomTextArea = (props) => {
  const {
    label,
    type,
    error,
    helperText,
    placeholder,
    onChange,
    onBlur,
    value,
    name,
    required,
    rows = 3,
  } = props;

  return (
    <Form.Group controlId={name}>
      <Form.Label className="textbox-label">
        {label}
        {required && <span>*</span>}
      </Form.Label>
      <Form.Control
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        as="textarea"
        rows={rows}
        placeholder={placeholder}
      />
      <Form.Text className={error ? " textbox-error" : "text-muted"}>
        {helperText}
      </Form.Text>
    </Form.Group>
  );
};
