import { useLazyQuery } from "@apollo/client";
import { GET_FEASIBILITY } from "../_queries";

export const useSurvey = () => {
  const [
    GetFeasibility,
    {
      data: feasibilityData = { GetFeasibility: { user: 0 } },
      loading: feasibilityLoading,
    },
  ] = useLazyQuery(GET_FEASIBILITY);

  const { GetFeasibility: Feasibility } = feasibilityData;

  return { GetFeasibility, feasibilityLoading, Feasibility };
};
