import Box from '@material-ui/core/Box';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    box: {
      borderStyle:'dashed',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      width:'100%',
      height:'200px'
    }
  }));

export const ImageContainer=()=>{
    const classes=useStyles()

    return(<Box className={classes.box}><PhotoCameraIcon/></Box>)
}