import { useMutation } from "@apollo/client";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";
import { CustomTextField } from "..";
import { ProcessGrapqlError } from "../..";
import { Context } from "../../store";
import { AlertHelper } from "../../_helpers";
import { ADD_ROLE, GET_ROLES, UPDATE_ROLE } from "../../_queries";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export const UpdateRole = () => {
  const classes = useStyles();
  const { state, setGlobalState } = useContext(Context);
  const [AddRole] = useMutation(ADD_ROLE, {
    onCompleted() {
      AlertHelper.success("Successfully added!");
      reset();
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddRole } }) {
      const existing = cache.readQuery({ query: GET_ROLES });
      const newData = [...existing.Roles, AddRole];
      cache.writeQuery({
        query: GET_ROLES,
        data: { Roles: newData },
      });
    },
  });

  const [UpdateRole] = useMutation(UPDATE_ROLE, {
    onCompleted() {
      AlertHelper.success("Successfully updated!");
      reset();
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateRole } }) {
      const existing = cache.readQuery({ query: GET_ROLES });
      const newData = existing.Roles.map((item) =>
        item.role_id !== UpdateRole.role_id ? item : UpdateRole
      );
      cache.writeQuery({
        query: GET_ROLES,
        data: { Roles: newData },
      });
    },
  });
  const { editing_role: editing = false, edit_role: editData = {} } = state;
  function reset() {
    setGlobalState({
      ...state,
      edit_role: {},
      editing_role: false,
      role_adding: false,
    });
  }
  return (
    <Formik
      initialValues={{
        role_name: `${editing ? editData.role_name : ""}`,
      }}
      validationSchema={Yup.object().shape({
        role_name: Yup.string().max(255).required("Role name is required"),
      })}
      onSubmit={(payload) => {
        if (editing) {
          AlertHelper.progress("Updating...");
          payload["role_id"] = editData.role_id;
          UpdateRole({ variables: payload });
        } else {
          AlertHelper.progress("Creating...");
          AddRole({ variables: payload });
        }
        reset();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography color="textPrimary" variant="h2">
              Create new Role
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              All fields are mandetory
            </Typography>
          </Box>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item md={12}>
              <CustomTextField
                error={Boolean(touched.role_name && errors.role_name)}
                fullWidth
                helperText={touched.role_name && errors.role_name}
                label="Role Name"
                margin="dense"
                placeholder="Enter role name"
                name="role_name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.role_name}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Box className={classes.button}>
            <Button
              onClick={() => {
                reset();
              }}
              variant="contained"
              color="default"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
                {
                  editing ? "Update" : "Save"
                }
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
