import { gql } from "@apollo/client";

export const GET_PROFILE_STAGES = gql`
  query GetProfileStagesByCountry($country: String!) {
    GetProfileStagesByCountry(country: $country) {
      stage_id
      stage_name
      order
      thumb_url
      length
      coin
      chip
      country
    }
  }
`;

export const GET_STAGES = gql`
  query GetProfileStages {
    GetProfileStages {
      stage_id
      stage_name
      order
      thumb_url
      length
      coin
      chip
      country
    }
  }
`;

export const ADD_PROFILE_STAGE = gql`
  mutation AddProfileStage(
    $stage_name: String!
    $order: Int!
    $thumb_url: String!
    $length: String!
    $coin: String!
    $chip: String!
    $country: String!
  ) {
    AddProfileStage(
      stage_name: $stage_name
      order: $order
      thumb_url: $thumb_url
      length: $length
      coin: $coin
      chip: $chip
      country: $country
    ) {
      stage_id
      stage_name
      order
      thumb_url
      length
      coin
      chip
      country
    }
  }
`;

export const UPDATE_PROFILE_STAGE = gql`
  mutation UpdateProfileStage(
    $stage_id: String!
    $stage_name: String!
    $order: Int!
    $thumb_url: String!
    $length: String!
    $coin: String!
    $chip: String!
    $country: String!
  ) {
    UpdateProfileStage(
      stage_id: $stage_id
      stage_name: $stage_name
      order: $order
      thumb_url: $thumb_url
      length: $length
      coin: $coin
      chip: $chip
      country: $country
    ) {
      stage_id
      stage_name
      order
      thumb_url
      length
      coin
      chip
      country
    }
  }
`;

export const DELETE_PROFILE_STAGE = gql`
  mutation DeleteProfileStage($stage_id: String!) {
    DeleteProfileStage(stage_id: $stage_id) 
  }
`;

export const GET_FORM_BY_STAGE = gql`
  query GetProfileFormById($stageId: String!) {
    GetProfileFormById(stage_id: $stageId) {
      stage_id
      component
    }
  }
`;

export const ADD_STAGE_FORM = gql`
  mutation Mutation($stageId: String!, $component: JSON!) {
    AddProfileForm(stage_id: $stageId, component: $component) {
      stage_id
      component
    }
  }
`;

export const UPDATE_STAGE_FORM = gql`
  mutation Mutation($stageId: String!, $component: JSON!) {
    UpdateProfileForm(stage_id: $stageId, component: $component) {
      stage_id
      component
    }
  }
`;

export const GET_PROFILE_FORMS = gql`
  query {
    GetProfileForm {
      stage_id
      component
    }
  }
`;
