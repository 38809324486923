import { useLazyQuery } from "@apollo/client";
import { GET_USER_LIST } from "../_queries";

export const useUser = () => {
  const [
    GetUsers,
    { data: userData = { GetUsersList: {} }, loading: UserListLoading },
  ] = useLazyQuery(GET_USER_LIST);

  const { GetUsersList } = userData;

  return {
    GetUsers,
    UsersList: GetUsersList,
    UserListLoading,
  };
};
