import { useMutation, useLazyQuery } from "@apollo/client";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  Fragment,
} from "react";
import * as Yup from "yup";
import { CustomSelectField, CustomTextField } from "..";
import { ProcessGrapqlError } from "../..";
import { Context } from "../../store";
import { AlertHelper, LoaderHelper } from "../../_helpers";
import {
  ADD_SURVEY,
  GET_SURVEY,
  UPDATE_SURVEY,
  GET_RULES_BY_SURVEY,
  GET_SURVEY_ID,
  GET_SURVEY_BY_ID,
} from "../../_queries";
import Country from "../../_json/countries.json";
import Rule from "./Rules";
import FormLabel from "@material-ui/core/FormLabel";
import { useSurvey } from "../../hooks";
import { CircularProgress, MenuItem } from "@mui/material";
import { CustomCheckbox } from "../global/CustomCheckbox";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export const Survey = () => {
  const classes = useStyles();

  const { state, setGlobalState } = useContext(Context);
  const {
    editing_survey: editing = false,
    edit_survey: editData = {},
    page,
    perpage,
  } = state;

  console.log("EDIT SURVEY", editData);

  const [AddSurvey] = useMutation(ADD_SURVEY, {
    onCompleted() {
      AlertHelper.success("Successfully added!");
      reset();
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddSurvey } }) {
      const existing = cache.readQuery({
        query: GET_SURVEY,
        variables: { page_no: perpage * (page - 1), per_page: perpage },
      });
      let arr = [...existing.Survey.survey];
      arr.unshift(AddSurvey);
      let newCount = existing.Survey.count;
      cache.writeQuery({
        query: GET_SURVEY,
        data: { Survey: { count: newCount, survey: arr } },
        variables: { page_no: perpage * (page - 1), per_page: perpage },
      });
    },
  });

  const [UpdateSurvey] = useMutation(UPDATE_SURVEY, {
    onCompleted() {
      AlertHelper.success("Successfully updated!");
      reset();
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateSurvey } }) {
      const existing = cache.readQuery({
        query: GET_SURVEY,
        variables: { page_no: perpage * (page - 1), per_page: perpage },
      });
      const newData = existing.Survey.survey.map((item) =>
        item.survey_id !== UpdateSurvey.survey_id ? item : UpdateSurvey
      );
      cache.writeQuery({
        query: GET_SURVEY,
        data: { Survey: { count: existing.Survey.count, survey: newData } },
        variables: { page_no: perpage * (page - 1), per_page: perpage },
      });
    },
  });

  function reset() {
    setGlobalState({
      ...state,
      edit_survey: {},
      editing_survey: false,
      survey_adding: false,
    });
  }
  const formRef = useRef();
  const Form = () => {
    return (
      <Formik
        initialValues={{
          survey_id: `${
            editing
              ? editData.survey_id
              : sid && sid.GetSurveyID
              ? sid.GetSurveyID.survey_Id
              : ""
          }`,
          survey_name: `${editing ? editData.survey_name : ""}`,
          length: `${editing ? editData.length : ""}`,
          start_date: `${editing ? editData.start_date : ""}`,
          expire_on: `${editing ? editData.expire_on : ""}`,
          coin: `${editing ? editData.coin : ""}`,
          chip: `${editing ? editData.chip : ""}`,
          url: `${editing ? editData.url : ""}`,
          rules: `${editing ? editData.rules : ""}`,
          thumb_url: `${editing ? editData.thumb_url : ""}`,
          is_featured: editing ? editData.is_featured : false,
          country: `${editing ? editData.country : ""}`,
          reputation: `${editing ? editData.reputation : ""}`,
          scheduleDatetime: `${editing ? editData.scheduleDatetime : ""}`,
          total: editing ? editData.total : 0,
        }}
        innerRef={formRef}
        validationSchema={Yup.object().shape({
          survey_id: Yup.string().required("Survey ID is required"),
          survey_name: Yup.string().required("Survey Name is required"),
          length: Yup.string().required("Length is required"),
          // start_date: Yup.string().required("Start Date is required"),
          // expire_on: Yup.string().required("Expire on is required"),
          // coin: Yup.string().required("Coin Name is required"),
          // chip: Yup.string().required("Chip is required"),
          url: Yup.string().required("URL is required"),
          thumb_url: Yup.string().required("Thumb URL is required"),
          country: Yup.string().required("Country is required"),
          reputation: Yup.number().required("Reputation is required"),
          scheduleDatetime: Yup.string().required(
            "Schedule Datetime is required"
          ),
          total: Yup.number().required("Total is required"),
        })}
        onSubmit={(payload) => {
          payload.rules = rulePayload;
          if (editing) {
            AlertHelper.progress("Updating...");
            payload["survey_id"] = editData.survey_id;
            UpdateSurvey({ variables: payload });
          } else {
            AlertHelper.progress("Creating...");
            AddSurvey({ variables: payload });
          }
          rulePayload = {};
        }}
      >
        {(props) => {
          const { errors, handleBlur, handleChange, touched, values } = props;
          const onChange = (e) => {
            return handleChange(e);
          };

          return (
            <form>
              <Box>
                <Typography color="textPrimary" variant="h2">
                  Create new Survey
                </Typography>
              </Box>
              <Grid container spacing={3} direction="row">
                <Grid item md={6}>
                  <CustomTextField
                    error={Boolean(touched.survey_id && errors.survey_id)}
                    fullWidth
                    helperText={touched.survey_id && errors.survey_id}
                    label="Survey ID"
                    margin="dense"
                    placeholder="Enter Survey ID"
                    name="survey_id"
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.survey_id}
                    variant="standard"
                    disabled={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <CustomTextField
                    error={Boolean(touched.survey_name && errors.survey_name)}
                    fullWidth
                    helperText={touched.survey_name && errors.survey_name}
                    label="Survey Name"
                    margin="dense"
                    placeholder="Enter Survey Name"
                    name="survey_name"
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.survey_name}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <CustomTextField
                    error={Boolean(touched.length && errors.length)}
                    fullWidth
                    helperText={touched.length && errors.length}
                    label="Length"
                    margin="dense"
                    placeholder="Enter Length"
                    name="length"
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.length}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <CustomTextField
                    error={Boolean(touched.start_date && errors.start_date)}
                    fullWidth
                    helperText={touched.start_date && errors.start_date}
                    label="Start Date"
                    margin="dense"
                    placeholder="Enter Start Date"
                    name="start_date"
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.start_date}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type={"date"}
                  />
                </Grid>
                <Grid item md={6}>
                  <CustomTextField
                    error={Boolean(touched.expire_on && errors.expire_on)}
                    fullWidth
                    helperText={touched.expire_on && errors.expire_on}
                    label="Expire on"
                    margin="dense"
                    placeholder="Enter Expire on"
                    name="expire_on"
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.expire_on}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type={"date"}
                  />
                </Grid>
                <Grid item md={6}>
                  <CustomTextField
                    error={Boolean(touched.coin && errors.coin)}
                    fullWidth
                    helperText={touched.coin && errors.coin}
                    label="Coin"
                    margin="dense"
                    placeholder="Enter Coin"
                    name="coin"
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.coin}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <CustomTextField
                    error={Boolean(touched.chip && errors.chip)}
                    fullWidth
                    helperText={touched.chip && errors.chip}
                    label="Chip"
                    margin="dense"
                    placeholder="Enter Chip"
                    name="chip"
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.chip}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <CustomTextField
                    error={Boolean(touched.url && errors.url)}
                    fullWidth
                    helperText={touched.url && errors.url}
                    label="URL"
                    margin="dense"
                    placeholder="Enter URL"
                    name="url"
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.url}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <CustomTextField
                    error={Boolean(touched.thumb_url && errors.thumb_url)}
                    fullWidth
                    helperText={touched.thumb_url && errors.thumb_url}
                    label="Thumb Url"
                    margin="dense"
                    placeholder="Enter Thumb Url"
                    name="thumb_url"
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.thumb_url}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <CustomCheckbox
                    fullWidth
                    helperText={touched.is_featured && errors.is_featured}
                    label="Featured"
                    margin="dense"
                    name="is_featured"
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.is_featured}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <CustomSelectField
                    select
                    error={Boolean(touched.country && errors.country)}
                    fullWidth
                    helperText={touched.country && errors.country}
                    label="Country"
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="country"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.country}
                  >
                    {Country.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </CustomSelectField>
                </Grid>
                <Grid item md={6}>
                  <CustomTextField
                    error={Boolean(touched.total && errors.total)}
                    fullWidth
                    helperText={touched.total && errors.total}
                    label="Total N"
                    margin="dense"
                    placeholder="Enter total N"
                    name="total"
                    type="number"
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.total}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <CustomTextField
                    error={Boolean(
                      touched.scheduleDatetime && errors.scheduleDatetime
                    )}
                    fullWidth
                    helperText={
                      touched.scheduleDatetime && errors.scheduleDatetime
                    }
                    type="datetime-local"
                    label="Schedule DateTime"
                    margin="dense"
                    name="scheduleDatetime"
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.scheduleDatetime}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <CustomTextField
                    error={Boolean(touched.reputation && errors.reputation)}
                    fullWidth
                    helperText={touched.reputation && errors.reputation}
                    label="Minimum Reputation"
                    margin="dense"
                    type="number"
                    name="reputation"
                    onBlur={handleBlur}
                    onChange={onChange}
                    value={values.reputation}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    );
  };

  // const [getRule, { data: dataRule }] = useLazyQuery(GET_RULES_BY_SURVEY);
  const [dataRule, setDataRule] = React.useState();
  const [getSurveyID, { data: sid }] = useLazyQuery(GET_SURVEY_ID, {
    fetchPolicy: "network-only",
  });
  const [GetSurveyByID, { data: currentSurvey }] = useLazyQuery(
    GET_SURVEY_BY_ID,
    {}
  );
  const [rulesvalue, setRulesValue] = useState({});
  const { GetFeasibility, Feasibility, feasibilityLoading } = useSurvey();
  useEffect(() => {
    if (editing) {
      setDataRule(JSON.parse(editData.rules));
      GetSurveyByID({
        variables: { survey_id: editData.survey_id },
        fetchPolicy: "network-only",
      });
    } else {
      getSurveyID();
    }
  }, [editing]);

  useEffect(() => {
    Object.assign(rulePayload, rulesvalue);
  }, [rulesvalue]);

  let rulePayload = {};
  useEffect(() => {
    if (dataRule) {
      setRulesValue(dataRule);
    }
  }, [dataRule]);

  const ruleValueAssign = (k, val) => {
    let ob = {};
    ob[k] = val;
    Object.assign(rulePayload, ob);
  };

  useEffect(() => {
    if (Feasibility.rules && Object.keys(Feasibility.rules).length !== 0) {
      setRulesValue(Feasibility.rules);
    }
  }, [Feasibility]);

  const handleForm = () => {
    if (editing) {
      if(currentSurvey){
        let final_data = (formRef.current.values = {
          ...formRef.current.values,
          active: currentSurvey.GetSurveyById.active,
          feasibility:
            Number(Feasibility?.user ?? currentSurvey.GetSurveyById.feasibility),
          rules_info: currentSurvey.GetSurveyById.rules_info,
        });
        UpdateSurvey({ variables: final_data });
      }
    } else {
      formRef.current.handleSubmit(rulePayload);
    }
  };

  const Fes = () => {
    return (
      <>
        <Grid item md={6}>
          <Button
            variant="outlined"
            onClick={() => {
              GetFeasibility({ variables: { rules: rulePayload } });
            }}
          >
            Check Feasibility
          </Button>
        </Grid>
        <Grid item md={6}>
          {feasibilityLoading && <CircularProgress />}
          {!feasibilityLoading && (
            <FormLabel component="legend">
              Feasibility: {Feasibility?.user ?? 0}
            </FormLabel>
          )}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Form />
      <Grid container spacing={3} direction="row">
        <Fes />
        <Grid item md={12}>
          <FormLabel component="legend">Rules</FormLabel>
          <Rule
            valueAssign={ruleValueAssign}
            rulesvalue={rulesvalue}
            country={editData.country}
          />
        </Grid>
      </Grid>
      <Box className={classes.button}>
        <Button
          onClick={() => {
            reset();
          }}
          variant="contained"
          color="default"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleForm}
        >
          {editing ? "Update" : "Save"}
        </Button>
      </Box>
    </>
  );
};
