import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Container,
  Grid,
  List,
  ListItem,
  Button,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CustomFormBuilder } from "..";
import { ProcessGrapqlError } from "../..";
import { Colors } from "../../_constants";
import { AlertHelper, LoaderHelper } from "../../_helpers";
import {
  ADD_STAGE_FORM,
  GET_FORM_BY_STAGE,
  GET_STAGES,
  UPDATE_STAGE_FORM,
} from "../../_queries";
const useStyles = makeStyles({
  cus_list_item: {
    paddingTop: "8px",
    paddingBottom: "8px",
    fontSize: "0.875rem",
  },
  active: {
    borderRight: `5px solid ${Colors.PRIMARY}`,
    color: `${Colors.PRIMARY}`,
  },
  cus_menu_list_icon: {
    minWidth: "45px",
  },
  table: {
    minWidth: 650,
  },
});
export const ProfileForm = () => {
  const [stage_id, SetSelectedStage] = useState(0);
  return (
    <>
      <Grid container spacing={0}>
        <Grid item md={2}>
          <Stages
            onChange={(e) => {
              SetSelectedStage(e);
            }}
          />
        </Grid>
        <Grid item md={10}>
          {stage_id && <FormBuilder stage_id={stage_id} />}
        </Grid>
      </Grid>
    </>
  );
};

const Stages = ({ onChange }) => {
  const classes = useStyles();
  const [active_id, SetActive] = useState(1);
  const { loading, error, data } = useQuery(GET_STAGES);
  if (loading) {
    LoaderHelper.load();
  } else {
    LoaderHelper.clear();
  }
  if (error) {
    ProcessGrapqlError(error);
  }
  function OpenForm(stage_id, stage_name) {
    SetActive(stage_id);
    onChange(stage_id);
  }
  useEffect(() => {
    const { GetProfileStages = false } = data;
    if (GetProfileStages && GetProfileStages.length > 0) {
      onChange(GetProfileStages[0].stage_id);
      SetActive(GetProfileStages[0].stage_id);
    }
  }, [data]);

  return (
    <>
      {/* <List className={classes.bredcrumb}>
        <ListItem>
          <ListItemText
            primary={
              <span className={classes.bredcrumbTitle}>Profile Stages</span>
            }
            secondary="Add Profile stage here"
          />
        </ListItem>
      </List> */}
      <div>
        <List>
          {data &&
            data.GetProfileStages.map((item, idx) => {
              return (
                <div key={idx}>
                  <ListItem
                    key={item.stage_id}
                    className={[
                      classes.cus_list_item,
                      item.stage_id == active_id ? classes.active : "",
                    ]}
                    onClick={() => {
                      OpenForm(item.stage_id, item.stage_name);
                    }}
                    button
                  >
                    {item.stage_name}
                  </ListItem>
                </div>
              );
            })}
        </List>
      </div>
    </>
  );
};

const FormBuilder = ({ stage_id: StageId }) => {
  const [form, SetForm] = useState({ display: "form" });

  const [isNew, SetIsNew] = useState(false);
  const [AddProfileForm] = useMutation(ADD_STAGE_FORM, {
    onCompleted(data) {
      const { AddProfileForm: { component } = false } = data;
      if (AddProfileForm) {
        AlertHelper.success("Successfully Saved!");
        SetForm(JSON.parse(component));
      }
    },
    onError(err) {
      ProcessGrapqlError(err);
    },
  });
  const [UpdateProfileForm] = useMutation(UPDATE_STAGE_FORM, {
    onCompleted(data) {
      const { UpdateProfileForm: { component } = false } = data;
      if (UpdateProfileForm) {
        AlertHelper.success("Successfully updated!");
        SetForm(JSON.parse(component));
      }
    },
    onError(err) {
      ProcessGrapqlError(err);
    },
  });
  const [GetProfileFormById] = useLazyQuery(GET_FORM_BY_STAGE, {
    fetchPolicy: `network-only`,
    onCompleted(data) {
      const {
        GetProfileFormById: {
          stage_id = false,
          // prettier-ignore
          component = "{ display: \"form\" }",
        } = false,
      } = data;

      if (stage_id) {
        SetIsNew(false);
        SetForm(JSON.parse(component));
      } else {
        SetIsNew(true);
        SetForm(JSON.parse(component));
      }
    },
    onError(err) {
      ProcessGrapqlError(err);
    },
  });

  useEffect(() => {
    GetProfileFormById({
      variables: { stageId: StageId },
    });
  }, [StageId]);
  function handleSave() {
    if (isNew) {
      AddProfileForm({
        variables: { stageId: StageId, component: JSON.stringify(form) },
      });
    } else {
      UpdateProfileForm({
        variables: { stageId: StageId, component: JSON.stringify(form) },
      });
    }
  }
  return (
    <>
      <Container className="container" fluid>
        {form && (
          <CustomFormBuilder
            name={StageId}
            onChange={(schema) => {
              SetForm(schema);
            }}
            form={form}
          />
        )}

        <div style={{ textAlign: "right" }}>
          <Button color={"primary"} onClick={handleSave} variant={"outlined"}>
            Save
          </Button>
        </div>
      </Container>
    </>
  );
};
