export const storageHelper = {
  Set,
  Get,
  Del,
  SetJson,
  GetJon,
};

function Set(key, value) {
  localStorage.setItem(key, value);
}

function Get(key) {
  return localStorage.getItem(key);
}
function SetJson(key, value) {
  //cookie.save(key, JSON.stringify(value), { path: '/' })
  localStorage.setItem(key, JSON.stringify(value));
}

function GetJon(key) {
  //return JSON.parse(cookie.load(key))
  return JSON.parse(localStorage.getItem(key));
}

function Del(key) {
  localStorage.removeItem(key);
}
