import { useQuery } from "@apollo/client";
import { MenuItem } from "@material-ui/core";
import React from "react";
import { CustomSelectField } from "./CustomSelectField";

export const CustomDropDown = (props) => {
  const { query, objname } = props;
  const { loading, error, data } = useQuery(query);
  if (error) {
    return <> </>;
  }

  return (
    <>
      {" "}
      <CustomSelectField select {...props}>
        <MenuItem key={""} value="">
          SELECT
        </MenuItem>
        {data &&
          data[objname].map((item, idx) => (
            <MenuItem key={idx} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
      </CustomSelectField>{" "}
    </>
  );
};
