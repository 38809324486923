import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React, { useContext, useState } from "react";
import { ProcessGrapqlError } from "..";
import { Context } from "../store";
import { AlertDialog, HalfScreenDialog, Survey } from "../_components";
import { AlertHelper, LoaderHelper } from "../_helpers";
import { DELETE_SURVEY, GET_SURVEY } from "../_queries";
import { Colors } from "../_constants";
import Pagination from "@material-ui/lab/Pagination";
import { PAGE } from "./../_constants";
import { Checkbox } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  bredcrumb: {
    backgroundColor: `${Colors.WHITE}`,
    marginBottom: "15px",
    borderRadius: "5px",
  },
  bredcrumbTitle: {
    fontSize: "16px",
    fontWeight: "600",
  },
  tableHeader: {
    backgroundColor: `${Colors.SECONDARY_LIGHT}`,
    padding: "15px",
  },
  paginationStyle: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
}));
export const SurveyList = () => {
  const classes = useStyles();
  const { state, setGlobalState } = useContext(Context);
  const [deleting, setDeleting] = useState(false);
  const [ref_id, setRefId] = useState("0");
  const [DeleteSurvey] = useMutation(DELETE_SURVEY, {
    onCompleted() {
      AlertHelper.success("Successfully deleted!");
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
  });
  const Delete = (id) => {
    setRefId(id);
    setDeleting(true);
  };
  const Negative = () => {
    setRefId("0");
    setDeleting(false);
  };
  const Positive = () => {
    setDeleting(false);
    AlertHelper.progress("Deleting...");
    DeleteSurvey({
      variables: { survey_id: ref_id },
      update(cache) {
        const existing = cache.readQuery({
          query: GET_SURVEY,
          variables: {
            page_no: PAGE.PER_PAGE * (page - 1),
            per_page: PAGE.PER_PAGE,
          },
        });
        const newSurvey = existing.Survey.survey.filter(
          (item) => item.survey_id !== ref_id
        );
        let newCount = existing.Survey.count;
        cache.writeQuery({
          query: GET_SURVEY,
          data: { Survey: { count: newCount, survey: newSurvey } },
          variables: {
            page_no: PAGE.PER_PAGE * (page - 1),
            per_page: PAGE.PER_PAGE,
          },
        });
      },
    });
  };
  const [page, setPage] = useState(1);

  const DataLists = () => {
    const surveyList = (event, value) => {
      setPage(value);
    };

    const { loading, error, data } = useQuery(GET_SURVEY, {
      variables: {
        page_no: PAGE.PER_PAGE * (page - 1),
        per_page: PAGE.PER_PAGE,
      },
    });
    if (loading) {
      LoaderHelper.load();
    } else {
      LoaderHelper.clear();
    }
    if (error) {
      console.log(error);
      ProcessGrapqlError(error);
    }

    return (
      <>
        <List className={classes.bredcrumb}>
          <ListItem>
            <ListItemText
              primary={
                <span className={classes.bredcrumbTitle}>Survey list</span>
              }
              secondary="Add Survey here"
            />
            <ListItemSecondaryAction>
              <Button
                startIcon={<AddBoxIcon />}
                onClick={() => {
                  setGlobalState({
                    ...state,
                    survey_adding: true,
                    editing_survey: false,
                    page: page,
                    perpage: PAGE.PER_PAGE,
                  });
                }}
                variant={"text"}
                color={"primary"}
              >
                Add New
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>Survey ID</TableCell>
                <TableCell className={classes.tableHeader}>
                  Survey Name
                </TableCell>
                <TableCell className={classes.tableHeader}>Length</TableCell>
                <TableCell className={classes.tableHeader}>
                  Start Date
                </TableCell>
                <TableCell className={classes.tableHeader}>Expire on</TableCell>
                <TableCell className={classes.tableHeader}>Coin</TableCell>
                <TableCell className={classes.tableHeader}>Chip</TableCell>
                <TableCell className={classes.tableHeader}>URL</TableCell>
                <TableCell className={classes.tableHeader}>Thumb Url</TableCell>
                <TableCell className={classes.tableHeader}>Country</TableCell>
                <TableCell className={classes.tableHeader}>Featured</TableCell>
                <TableCell className={classes.tableHeader}>Action</TableCell>
                <TableCell className={classes.tableHeader}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.Survey.survey.map((row) => (
                  <TableRow key={row.survey_id}>
                    <TableCell component="th" scope="row">
                      {row.survey_id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.survey_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.length}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.start_date}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.expire_on}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.coin}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.chip}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <a href={row.url} target={"_blank"}>
                        Url
                      </a>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <a href={row.thumb_url} target={"_blank"}>
                        Thumbnail
                      </a>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.country}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Checkbox checked={row.is_featured} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        onClick={() => {
                          setGlobalState({
                            ...state,
                            edit_survey: row,
                            editing_survey: true,
                            survey_adding: true,
                            page: page,
                            perpage: PAGE.PER_PAGE,
                          });
                        }}
                        aria-label="delete"
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        onClick={() => Delete(row.survey_id)}
                        aria-label="delete"
                      >
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {data && data.Survey.count > 1 && (
          <Grid container justify="flex-end">
            <div className={classes.paginationStyle}>
              <Pagination
                count={data && data.Survey.count}
                variant="outlined"
                color="primary"
                onChange={surveyList}
                page={page}
              />
            </div>
          </Grid>
        )}
      </>
    );
  };
  return (
    <>
      {deleting && (
        <AlertDialog
          open={true}
          negative={Negative}
          positive={Positive}
          title={"Warning"}
          description={"Are you sure you want to delete?"}
        />
      )}
      {state.survey_adding && (
        <HalfScreenDialog
          open={true}
          component={<Survey />}
          CloseCallback={() => {
            setGlobalState({
              ...state,
              survey_adding: false,
            });
          }}
        />
      )}
      <Container maxWidth={false}>
        <Box className={classes.container}>
          <DataLists />
        </Box>
      </Container>
    </>
  );
};
