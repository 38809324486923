import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@material-ui/core";
import { DashboardNavbar, DashboardSidebar } from "..";
import { Colors, URLS } from "../../_constants";
import { storageHelper } from "../../_helpers";
import { useNavigate } from "react-router-dom";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: `${Colors.MAIN_BG}`,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
  paddingLeft: "0px !important",
  paddingRight: "0px !important",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

export const DashboardLayout = () => {
  const navigate = useNavigate();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  useEffect(() => {
    const auth = storageHelper.Get("auth");
    if (!auth || auth !== "true") {
      navigate("/" + URLS.LOGIN, { replace: true });
    }
  }, []);
  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />

      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};
