export const URLS = {
  LOGIN: "login",
  STAFF: "staff",
  ROLE: "role",
  PROFILE: "profile-setup",
  SETTINGS: "settings",
  SURVEY: "survey",
  USERS: "users",
  SWEEPSTACK: "sweepstack",
  BLOG:"blogs",
  ROUTER:"router"
};
