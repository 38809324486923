import { useLazyQuery } from "@apollo/client";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid, Paper } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ComponentHelper } from "../../_helpers";
import { GET_PROFILE_FORMS, GET_PROFILE_STAGES } from "../../_queries";

const Rules = ({ valueAssign, rulesvalue, country }) => {
  const [getPS, { data: dataStage }] = useLazyQuery(GET_PROFILE_STAGES);
  const [getPF, { data: dataForm }] = useLazyQuery(GET_PROFILE_FORMS);
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    getPS({ variables: { country } });
    getPF();
  }, []);

  useEffect(() => {
    if (dataStage) {
      var a1 = dataStage.GetProfileStagesByCountry;
    }
    if (dataForm) {
      var a2 = dataForm.GetProfileForm;
    }
    if (a1 && a2) {
      let mergeData = a1.map((t1) => ({
        ...t1,
        ...a2.find((t2) => t2.stage_id === t1.stage_id),
      }));
      setNewData(mergeData);
    }
  }, [dataStage, dataForm]);

  const CustomFormik = ({ component, i }) => {
    return (
      <Formik
        initialValues={ComponentHelper.GetInitialValue(
          component,
          true,
          rulesvalue,
          ""
        )}
      >
        {(props) => {
          const { errors, handleBlur, handleChange, touched, values } = props;
          const onChange = (e) => {
            valueAssign(e.target.name, e.target.value);
            return handleChange(e);
          };
          return (
            <Paper
              sx={{
                display: "flex",
                pl: 2,
                pb: 2,
                pt: 2,
                overflowWrap: "anywhere",
              }}
            >
              <ComponentHelper.GetComponent
                component={component}
                touched={touched}
                onBlur={handleBlur}
                errors={errors}
                values={values}
                onChange={onChange}
              />
            </Paper>
          );
        }}
      </Formik>
    );
  };

  if (newData.length === 0) {
    return <CircularProgress />;
  }

  return (
    <>
      {newData &&
        newData.map((row) => (
          <Accordion key={row.stage_id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{row.stage_name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} direction="row">
                {JSON.parse(row.component)?.components?.map((rowform, idx) => {
                  if (rowform.key !== "consent") {
                    return (
                      <Grid item md={12} key={idx}>
                        <CustomFormik component={rowform} i={idx} />
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};

export default Rules;
