import { gql, useLazyQuery } from "@apollo/client";

export const ADD_BLOG = gql`
  mutation AddBlog($title: String!, $contents: String!, $images: String) {
    AddBlog(title: $title, contents: $contents, images: $images) {
      title
      contents
      images
    }
  }
`;

export const UPDATE_BLOG = gql`
  mutation UpdateBlog(
    $id: String!
    $title: String!
    $contents: String!
    $images: String
  ) {
    UpdateBlog(id: $id, title: $title, contents: $contents, images: $images) {
      id
      title
      contents
      images
    }
  }
`;

export const GET_BLOGS = gql`
  query {
    Blog: GetBlogs {
      id
      title
      contents
      images
    }
  }
`;

export const GET_BLOG_BY_ID = gql`
  query GetBlogById($id: String!) {
    GetBlogById(id: $id) {
      id
      title
      contents
      images
    }
  }
`;

export const DELETE_BLOG = gql`
  mutation DeleteBlog($id: String!) {
    DeleteBlog(id: $id) {
      id
      title
      contents
      images
    }
  }
`;

export const useBlog = () => {
  const useGetBlogs = useLazyQuery(GET_BLOGS);

  return {
    useGetBlogs,
  };
};
