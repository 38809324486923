import { useContext } from "react";
import { Context } from "../store";

export const useGlobalState = (prev) => {
  const { state, setGlobalState } = useContext(Context);
  if (prev) {
    setGlobalState(state);
  }
  return { state, setGlobalState };
};
