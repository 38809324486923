import { alertService } from "./alertService";

export const LoaderHelper = {
  load,
  clear,
};

function load() {
  alertService.sendMessage("", "LOAD", "PROGRESS");
}

function clear() {
  alertService.sendMessage("", "LOAD", "CLOSE");
}
