import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { CustomTextField } from "../..";

import { useConfig, useGlobalState } from "../../../hooks";
import { AlertHelper } from "../../../_helpers";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export const UpdateConfig = ({ config }) => {
  const classes = useStyles();
  const { state, setGlobalState } = useGlobalState();
  const { AddConfig, UpdateConfig } = useConfig(config, (action, res) => {
    reset();
  });
  const { edit_config: editing = false, data_config: editData = {} } = state;
  function reset() {
    setGlobalState({
      ...state,
      data_config: {},
      edit_config: false,
      add_config: false,
    });
  }
  return (
    <Formik
      initialValues={{
        name: `${editing ? editData.name : ""}`,
        code: `${editing ? editData.code : ""}`,
        value: `${editing ? editData.value : ""}`,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required("Required"),
        code: Yup.string().max(255).required("Required"),
        value: Yup.string().max(255).required("Required"),
      })}
      onSubmit={(payload) => {
        let data = {
          config: config,
          name: payload.name,
          code: payload.code,
          value: payload.value,
        };
        if (editing) {
          AlertHelper.progress("Updating...");
          data["configId"] = editData.config_id;
          UpdateConfig({ variables: data });
        } else {
          AlertHelper.progress("Creating...");
          AddConfig({ variables: data });
        }
        reset();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography color="textPrimary" variant="h2">
              Create new
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              All fields are mandetory
            </Typography>
          </Box>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item md={12}>
              <CustomTextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Config Name"
                margin="dense"
                placeholder="Enter config name"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item md={12}>
              <CustomTextField
                error={Boolean(touched.code && errors.code)}
                fullWidth
                helperText={touched.code && errors.code}
                label="Config code"
                margin="dense"
                placeholder="Enter unique code"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item md={12}>
              <CustomTextField
                error={Boolean(touched.value && errors.value)}
                fullWidth
                helperText={touched.value && errors.value}
                label="Config Value"
                margin="dense"
                placeholder="Enter config value"
                name="value"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.value}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Box className={classes.button}>
            <Button
              onClick={() => {
                reset();
              }}
              variant="contained"
              color="default"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
