import { Outlet } from "react-router-dom";
import { styled } from "@material-ui/core";
import React from "react";
import { Colors } from "../../_constants";

const MainLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: `${Colors.MAIN_BG}`,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const MainLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
}));

const MainLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const MainLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

export const MainLayout = () => {
  return (
    <MainLayoutRoot>
      <MainLayoutWrapper>
        <MainLayoutContainer>
          <MainLayoutContent>
            <Outlet />
          </MainLayoutContent>
        </MainLayoutContainer>
      </MainLayoutWrapper>
    </MainLayoutRoot>
  );
};
