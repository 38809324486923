export const Colors = {
  PRIMARY: `${"#2196F3"}`,
  PRIMARY_DARK: `${"#1976D2"}`,
  PRIMARY_LIGHT: `${"#00d3f5"}`,
  PRIMARY_ACCENT: `${"#0f0f0f"}`,
  SECONDARY: `${"#00acc1"}`,
  SECONDARY_DARK: `${"#0292a3"}`,
  SECONDARY_LIGHT: `${"#BBDEFB"}`,
  SECONDARY_ACCENT: `${"#FFFFFF"}`,
  YELLOW: "#FFEB3B",
  ACCENT: `${"#FF4081"}`,
  ACCENT_SECONDARY: `${"rgba(210,241,248,0.62)"}`,
  TEXT_PRIMARY: `${"#212121"}`,
  TEXT_SECONDARY: `${"#757575"}`,
  WHITE: `${"#FFFFFF"}`,
  TEXT_GREY: `${"#8C8C8C"}`,
  GREY: `${"#8C8C8C"}`,
  LIGHT_GREY: `${"#cecdcd"}`,
  MAIN_BG: "#f5f5f5",
  CONTRAST_GREY: `${"rgb(234, 237, 237)"}`,
  CONTRAST_WHITE: `${"#FFFFFF"}`,
  CONTRAST_WHITE_black: `${"#FFFFFF"}`,

  INPUT_BG: `${"rgba(170,170,170,0)"}`,
  ICON_COLOR: `${"rgb(75,74,74)"}`,
  RED: `${"#ff1744"}`,
  CUSTOM_TEXT: "#646262",
  SURVEY_NAME: "#5B49F1",

  VIOLET: `${"#6558F5"}`,
  GREEN_MINT: `${"#8BCB98"}`,
  ORANGE: `${"#ED7014"}`,
  SUPER_LIGHT_GREY: "rgb(249 246 247 / 89%)",
  DEVIDER: "#DFE0DF",
};
