import {
  Box,
  Button,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";
import { CustomSelectField, CustomTextField } from "..";
import { ProcessGrapqlError } from "../..";
import { GET_SWEEPSTACKS, useSweepStack } from "../../hooks";
import { Context } from "../../store";
import { AlertHelper } from "../../_helpers";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export const UpdateSweepstack = () => {
  const classes = useStyles();
  const { state, setGlobalState } = useContext(Context);
  const { useAddSweepStack, useUpdateSweepStack } = useSweepStack();

  const [AddSweepStack] = useAddSweepStack;
  const [UpdateSweepStack] = useUpdateSweepStack;

  const { editing_sweep: editing = false, edit_sweep: editData = {} } = state;
  function reset() {
    setGlobalState({
      ...state,
      edit_sweep: {},
      editing_sweep: false,
      sweep_adding: false,
    });
  }
  return (
    <Formik
      initialValues={{
        title: `${editing ? editData.title : ""}`,
        details: `${editing ? editData.details : ""}`,
        logo: `${editing ? editData.logo : ""}`,
        status: `${editing ? editData.status : "Upcoming"}`,
        type: `${editing ? editData.type : "MONTH"}`,
        startDateTime: `${editing ? editData.start_date_time : ""}`,
        endDateTime: `${editing ? editData.end_date_time : ""}`,
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255).required("Title is required"),
        details: Yup.string().max(255).required("Details is required"),
        logo: Yup.string().max(255).required("Logo url is required"),
        status: Yup.string().max(255).required("Status is required"),
        type: Yup.string().max(255).required("Type is required"),
        startDateTime: Yup.string()
          .max(255)
          .required("Start date time is required"),
        endDateTime: Yup.string()
          .max(255)
          .required("End date time is required"),
      })}
      onSubmit={(payload) => {
        if (editing) {
          AlertHelper.progress("Updating...");
          payload["uuid"] = editData.uuid;
          UpdateSweepStack({
            variables: payload,
            onCompleted() {
              AlertHelper.success("Successfully updated!");
              reset();
            },
            onError(error) {
              ProcessGrapqlError(error);
            },
            update(cache, { data: { UpdateSweepStack } }) {
              const existing = cache.readQuery({ query: GET_SWEEPSTACKS });
              const newData = existing.GetSweepStacks.map((item) =>
                item.user_id !== UpdateSweepStack.uuid ? item : UpdateSweepStack
              );
              cache.writeQuery({
                query: GET_SWEEPSTACKS,
                data: { GetSweepStacks: newData },
              });
            },
          });
        } else {
          AlertHelper.progress("Creating...");
          AddSweepStack({
            variables: payload,
            onCompleted() {
              AlertHelper.success("Successfully added!");
              reset();
            },
            onError(error) {
              ProcessGrapqlError(error);
            },
            update(cache, { data: { AddSweepStack } }) {
              const existing = cache.readQuery({
                query: GET_SWEEPSTACKS,
              });
              const newData = [...existing.GetSweepStacks, AddSweepStack];
              cache.writeQuery({
                query: GET_SWEEPSTACKS,
                data: { GetSweepStacks: newData },
              });
            },
          });
        }
        reset();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography color="textPrimary" variant="h2">
              Create new Sweepstack
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              All fields are mandetory
            </Typography>
          </Box>
          <Grid container spacing={3} direction="row">
            <Grid item md={6}>
              <CustomTextField
                error={Boolean(touched.title && errors.title)}
                fullWidth
                helperText={touched.title && errors.title}
                label="Title"
                margin="dense"
                placeholder="Enter sweepstack title"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                error={Boolean(touched.details && errors.details)}
                fullWidth
                helperText={touched.details && errors.details}
                label="Details"
                margin="dense"
                placeholder="Enter sweepstack details"
                name="details"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.details}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                error={Boolean(touched.logo && errors.logo)}
                fullWidth
                helperText={touched.logo && errors.logo}
                label="Logo url"
                margin="dense"
                placeholder="Enter logo url"
                name="logo"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.logo}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6}>
              <CustomSelectField
                select
                error={Boolean(touched.type && errors.type)}
                fullWidth
                helperText={touched.type && errors.type}
                label="Type of Sweepstack"
                margin="dense"
                name="type"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
              >
                <MenuItem value={"MONTH"}>MONTH</MenuItem>
                <MenuItem value={"YEAR"}>YEAR</MenuItem>
              </CustomSelectField>
            </Grid>
            <Grid item md={6}>
              <CustomSelectField
                select
                error={Boolean(touched.status && errors.status)}
                fullWidth
                helperText={touched.status && errors.status}
                label="Status"
                margin="dense"
                name="status"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.status}
              >
                <MenuItem value={"In progress"}>Active</MenuItem>
                <MenuItem value={"Upcoming"}>Upcoming</MenuItem>
                <MenuItem value={"Done"}>Done</MenuItem>
              </CustomSelectField>
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                error={Boolean(touched.startDateTime && errors.startDateTime)}
                fullWidth
                helperText={touched.startDateTime && errors.startDateTime}
                type="datetime-local"
                label="Start Date time"
                margin="dense"
                placeholder="Select Date time"
                name="startDateTime"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.startDateTime}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                error={Boolean(touched.endDateTime && errors.endDateTime)}
                fullWidth
                helperText={touched.loendDateTimego && errors.endDateTime}
                type="datetime-local"
                label="End Date time"
                margin="dense"
                placeholder="Select Date time"
                name="endDateTime"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.endDateTime}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Box className={classes.button}>
            <Button
              onClick={() => {
                reset();
              }}
              variant="contained"
              color="default"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {editing ? "Update" : "Save"}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
