import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Colors } from "../_constants";
import { Divider } from "@material-ui/core";
import { Role, Staff } from ".";
import { ProfileForm, ProfileStages } from "../_components";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: `${Colors.MAIN_BG}`,
  },
  settingTabs: {
    backgroundColor: `${Colors.WHITE}`,
  },
  tabPanel: {
    padding: "0px !important",
  },
}));

export const ProfileSetUp = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <Tabs
        className={classes.settingTabs}
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="Profile Stages" {...a11yProps(0)} />
        <Tab label="Profile Form" {...a11yProps(1)} />
      </Tabs>
      <Divider />

      <TabPanel className={classes.tabPanel} value={value} index={0}>
        <ProfileStages />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={1}>
        <ProfileForm />
      </TabPanel>
    </div>
  );
};
