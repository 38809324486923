import { useMutation } from "@apollo/client";
import React, { useContext } from "react";
import { Formik } from "formik";
import { AlertHelper } from "../../_helpers";
import { ProcessGrapqlError } from "../..";
import { Context } from "../../store";
import { Grid, Button } from "@material-ui/core";
import { CustomTextField } from "../global/CustomTextField";
import { CustomQuillText } from "../global/CustomQuillText";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { ImageContainer } from "./ImageBox";
import { makeStyles, Typography } from "@material-ui/core";
import { GET_BLOGS, UPDATE_BLOG, ADD_BLOG } from "../../hooks";
import { config } from "./ConfigAWS";
import S3FileUpload from "react-s3";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

const useStyles = makeStyles((theme) => ({
  body: {
    maxHeight: "80vh",
    maxWidth: "60vw",
    overflowY: "scroll",
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  imageList: {
    flexWrap: "wrap",
    justifyContent: "space-around",
    width: "100%",
    margin: 0,
    height: 400,
    transform: "translateZ(0)",
    // Hide Scrollbar
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome */,
    },
  },
}));

export const UpdateBlog = (props) => {
  const classes = useStyles();
  const { state, setGlobalState } = useContext(Context);

  const [AddBlog] = useMutation(ADD_BLOG, {
    onCompleted() {
      AlertHelper.success("Successfully added!");
      reset();
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { ADD_BLOG } }) {
      const existing = cache.readQuery({ query: GET_BLOGS });
      const newData = [...existing.Blog, ADD_BLOG];
      cache.writeQuery({
        query: GET_BLOGS,
        data: { Blog: newData },
      });
    },
  });

  const [UpdateBlog] = useMutation(UPDATE_BLOG, {
    onCompleted() {
      AlertHelper.success("Successfully updated!");
      reset();
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateBlog } }) {
      const existing = cache.readQuery({ query: GET_BLOGS });
      const newData = existing.Blogs.map((item) =>
        item.id !== UpdateBlog.id ? item : UpdateBlog
      );
      cache.writeQuery({
        query: GET_BLOGS,
        data: { Blogs: newData },
      });
    },
  });
  const { editing_blog: editing = false, edit_blog: editData = {} } = state;
  function reset() {
    setGlobalState({
      ...state,
      edit_blog: {},
      editing_blog: false,
      blog_adding: false,
    });
  }

  return (
    <Formik
      initialValues={{
        title: `${editing ? editData.title : ""}`,
        contents: editing
          ? convertQuillDeltaToHtml(JSON.parse(editData.contents))
          : "",
        images: editing ? JSON.parse(editData.images) : [],
      }}
      validationSchema={false}
      onSubmit={async (payload) => {
        if (editing) {
          AlertHelper.progress("Updating...");
          payload["id"] = editData.id;
          payload.images.map((file) => S3FileUpload.uploadFile(file, config));
          payload &&
            payload.contents &&
            payload.contents.ops.forEach((data) => {
              if (data.insert.image) {
                payload.images.push(data.insert.image);
              }
            });
          const variables = {
            ...payload,
            contents: JSON.stringify(payload.contents.ops),
            images: JSON.stringify(payload.images.map(file=>`${config.url}/${config.dirName}/${file.name}`)),
          };
          UpdateBlog({ variables: variables });
          props.CloseCallback();
        } else {
          AlertHelper.progress("Creating...");
          payload.images.map((file) => S3FileUpload.uploadFile(file, config));
          payload &&
            payload.contents &&
            payload.contents.ops.forEach((data) => {
              if (data.insert.image) {
                payload.images.push(data.insert.image);
              }
            });
          const variables = {
            ...payload,
            contents: JSON.stringify(payload.contents.ops),
            images: JSON.stringify(payload.images.map(file=>`${config.url}/${config.dirName}/${file.name}`)),
          };
          AddBlog({ variables: variables });
          props.CloseCallback();
        }
        reset();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} className={classes.body}>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item md={10}>
              <CustomTextField
                error={Boolean(touched.title && errors.title)}
                fullWidth
                helperText={touched.title && errors.title}
                label="Title"
                margin="dense"
                placeholder="Enter Title"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={10}>
              <Typography color="textSecondary" variant="h6">
                Contents
              </Typography>
              <CustomQuillText
                name="contents"
                setFieldValue={setFieldValue}
                value={values.contents}
                images={values.images}
              />
            </Grid>
            <Grid item md={10}>
              <Button variant="contained" color="primary" component="label">
                Upload
                <input
                  hidden
                  accept="image/*"
                  multiple
                  name="images"
                  type="file"
                  onChange={(e) =>
                    setFieldValue(
                      "images",
                      values.images.concat(e.target.files[0])
                    )
                  }
                />
              </Button>
            </Grid>
            <Grid item md={10}>
              {values.images.length ? (
                <ImageList
                  className={classes.imageList}
                  rowHeight={500}
                  gap={8}
                  cols={1}
                >
                  {values.images.map((item) => {
                    return (
                      <ImageListItem key={item.img} cols={item.cols || 1}>
                        <img
                          src={editing ? item : URL.createObjectURL(item)}
                          alt={item.title}
                          loading="lazy"
                        />
                      </ImageListItem>
                    );
                  })}
                </ImageList>
              ) : (
                <ImageContainer />
              )}
            </Grid>
            <Grid item md={10}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginRight: "1rem" }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  props.CloseCallback();
                }}
                variant="contained"
                color="default"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

const convertQuillDeltaToHtml = (deltaOps) => {
  const cfg = {};
  const converter = new QuillDeltaToHtmlConverter(deltaOps, cfg);
  return converter.convert();
};
