import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { FormBuilder } from "@formio/react";
import "formiojs/dist/formio.builder.min.css";

export const CustomFormBuilder = (props) => {
  const { name } = props;
  useEffect(() => {
    ReactDOM.render(
      <FormBuilder
        {...props}
        options={{
          builder: {
            basic: false,
            advanced: false,
            data: false,
            premium: false,
            layout: false,
            customAdditionalFormFields: {
              title: "Components",
              default: true,
              weight: 0,
              components: {
                email: true,
                time: true,
                datetime: true,
                phoneNumber: true,
                number: true,
                textfield: true,
                textarea: true,
                checkbox: true,
                radio: true,
                select: true,
                url: true,
                file: true,
              },
            },
          },
          editForm: {
            email: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            day: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            time: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            datetime: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            phoneNumber: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            number: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            address: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            signature: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            textfield: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            textarea: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            button: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            checkbox: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            radio: [
              { key: "api", ignore: false },
              { key: "data", ignore: false },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            select: [
              { key: "api", ignore: false },
              { key: "data", ignore: false },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            selectboxes: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            table: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            columns: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            url: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
            file: [
              { key: "api", ignore: false },
              { key: "data", ignore: true },
              { key: "conditional", ignore: true },
              { key: "logic", ignore: true },
              { key: "layout", ignore: true },
            ],
          },
        }}
      />,
      document.getElementById(name)
    );
  }, [props]);

  return <div id={name}></div>;
};
