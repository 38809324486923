import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

export const CustomMultiCheckbox = (props) => {
	const {
		multiple,
		error,
		helperText,
		onChange,
		onBlur,
		id,
		options,
		value = [],
		name,
		label,
		required,
		hideLabel,
		key
	} = props;

	let checkValue = {};
	if (value.length > 0) {
		checkValue = value.reduce((obj, item) => {
			obj[item] = true;
			return obj;
		},{});
	}
	let checks = checkValue
	// const [checks, SetChecks] = useState(checkValue, {});
	const handleChange = (e, callback) => {
		if (multiple) {
			checks = { ...checks, [e.target.name]: e.target.checked };
			// SetChecks({
			//   ...checks,
			//   [e.target.name]: e.target.checked,
			// });
			let output = [];
			if(Object.keys(checks).length > 0){
				output = Object.entries(checks).map(([ key, value ]) => ({
					key,
					value
				}));
			}
			let values = [];
			if (output.length > 0) {
				values = output.reduce((arr, item) => {
					if (item.value) {
						arr.push(item.key);
					}
					return arr;
				}, []);
			}

			callback(
				onChange({
					type: 'select',
					target: { value: values, id: name, name: name }
				})
			);
			
		} else {
			checks = {
				checked: e.target.name
			};
			
			callback(
				onChange({
					type: 'select',
					target: { value: checks, id: name, name: name }
				})
			);
		}
		
	};

	return (
		<Form.Group key={key} controlId={name}>
			{!hideLabel && (
				<Form.Label className="textbox-label">
					{label}
					{required && <span>*</span>}
				</Form.Label>
			)}
			
			<FormGroup>
				{options &&
					options.length > 0 &&
					options.map((elm, idx) => (
						<FormControlLabel
							control={
								<Checkbox
									checked={
										checks.hasOwnProperty(elm.value) ? checks[elm.value] : false
									}
									onChange={(e) => {handleChange(e,()=>{})}}
									name={elm.value}
								/>
							}
							label={elm.label}
							key={idx}
						/>
					))}
			</FormGroup>
			<Form.Text className={error ? ' textbox-error' : 'text-muted'}>{helperText}</Form.Text>
		</Form.Group>
	);
};
