import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React, { useContext, useState } from "react";
import { ProcessGrapqlError } from "../..";
import { Context } from "../../store";
import { AlertDialog, HalfScreenDialog, UpdateRole } from "../../_components";
import { AlertHelper, LoaderHelper } from "../../_helpers";
import { Colors } from "../../_constants";
import { DELETE_PROFILE_STAGE, GET_STAGES } from "../../_queries";
import { UpdateProfileStage } from "./UpdateProfileStage";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  bredcrumb: {
    backgroundColor: `${Colors.WHITE}`,
    marginBottom: "15px",
    borderRadius: "5px",
  },
  bredcrumbTitle: {
    fontSize: "16px",
    fontWeight: "600",
  },
  tableHeader: {
    backgroundColor: `${Colors.SECONDARY_LIGHT}`,
    padding: "15px",
  },
}));
export const ProfileStages = () => {
  const classes = useStyles();
  const { state, setGlobalState } = useContext(Context);
  const [deleting, setDeleting] = useState(false);
  const [ref_id, setRefId] = useState("0");
  const [DeleteProfileStage] = useMutation(DELETE_PROFILE_STAGE, {
    onCompleted() {
      AlertHelper.success("Successfully deleted!");
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
  });
  const Delete = (id) => {
    setRefId(id);
    setDeleting(true);
  };
  const Negative = () => {
    setRefId("0");
    setDeleting(false);
  };
  const Positive = () => {
    setDeleting(false);

    AlertHelper.progress("Deleting...");
    DeleteProfileStage({
      variables: { stage_id: ref_id },
      update(cache) {
        const existing = cache.readQuery({ query: GET_STAGES });
        const newItems = existing.GetProfileStages.filter(
          (item) => item.stage_id !== ref_id
        );
        cache.writeQuery({
          query: GET_STAGES,
          data: { GetProfileStages: newItems },
        });
      },
    });
  };

  const DataLists = () => {
    const { loading, error, data } = useQuery(GET_STAGES);
    if (loading) {
      LoaderHelper.load();
    } else {
      LoaderHelper.clear();
    }
    if (error) {
      ProcessGrapqlError(error);
    }

    return (
      <>
        <List className={classes.bredcrumb}>
          <ListItem>
            <ListItemText
              primary={
                <span className={classes.bredcrumbTitle}>Profile Stages</span>
              }
              secondary="Add Profile stage here"
            />
            <ListItemSecondaryAction>
              <Button
                startIcon={<AddBoxIcon />}
                onClick={() => {
                  setGlobalState({
                    ...state,
                    stage_adding: true,
                    editing_stage: false,
                  });
                }}
                variant={"text"}
                color={"primary"}
              >
                Add New
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>
                  Stage Name
                </TableCell>
                <TableCell className={classes.tableHeader}>Order</TableCell>
                <TableCell className={classes.tableHeader}>Thumbnail</TableCell>
                <TableCell className={classes.tableHeader}>Length</TableCell>
                <TableCell className={classes.tableHeader}>Chip</TableCell>
                <TableCell className={classes.tableHeader}>Coin</TableCell>
                <TableCell className={classes.tableHeader}>Country</TableCell>
                <TableCell className={classes.tableHeader}>Action</TableCell>
                <TableCell className={classes.tableHeader}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.GetProfileStages?.map((row) => {
                  return (
                    <TableRow key={row.stage_id}>
                      <TableCell component="th" scope="row">
                        {row.stage_name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.order}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <a href={row.thumb_url} target="_blank">
                          Thumbnail
                        </a>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.length}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.coin}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.chip}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.country}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <IconButton
                          onClick={() => {
                            setGlobalState({
                              ...state,
                              edit_stage: row,
                              editing_stage: true,
                              stage_adding: true,
                            });
                          }}
                          aria-label="delete"
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <IconButton
                          onClick={() => Delete(row.stage_id)}
                          aria-label="delete"
                        >
                          <CloseIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };
  return (
    <>
      {deleting && (
        <AlertDialog
          open={true}
          negative={Negative}
          positive={Positive}
          title={"Warning"}
          description={"Are you sure you want to delete?"}
        />
      )}
      {state.stage_adding && (
        <HalfScreenDialog
          open={true}
          component={<UpdateProfileStage />}
          CloseCallback={() => {
            setGlobalState({
              ...state,
              stage_adding: false,
            });
          }}
        />
      )}
      <Container maxWidth={false}>
        <Box className={classes.container}>
          <DataLists />
        </Box>
      </Container>
    </>
  );
};
