import { gql } from "@apollo/client";

export const ADD_ROLE = gql`
  mutation AddRole($role_name: String!) {
    AddRole(role_name: $role_name) {
      role_id
      role_name
    }
  }
`;
export const UPDATE_ROLE = gql`
  mutation UpdateRole($role_id: String!, $role_name: String!) {
    UpdateRole(role_id: $role_id, role_name: $role_name) {
      role_id
      role_name
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($role_id: String!) {
    DeleteRole(role_id: $role_id) {
      role_id
    }
  }
`;
export const GET_ROLES = gql`
  query {
    Roles: GetRoles {
      role_id
      role_name
    }
  }
`;

export const ADD_ROLE_TO_CACHE = gql`
  fragment NewRole on Roles {
    role_id
    role_name
  }
`;
export const GET_ROLE_DROP_DOWN = gql`
  query {
    RoleDropDown: GetRoles {
      value: role_id
      label: role_name
    }
  }
`;
