import React, { useRef, useMemo } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import S3FileUpload from "react-s3";
import {config} from "../blog/ConfigAWS";


export const CustomQuillText = ({ setFieldValue, value }) => {
  const quillRef = useRef("");

  const handleProcedureContentChange = (content, delta, source, editor) => {
    setFieldValue("contents", editor.getContents());
  };

  const imageHandler = (a) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      // file type is only image.
      if (/^image\//.test(file.type)) {
        S3FileUpload.uploadFile(file, config)
          .then((data) => insertToEditor(data.location))
          .catch((err) => console.error(err));
      } else {
        console.warn("You could only upload images.");
      }
    };
  };

  const insertToEditor = (url) => {
    quillRef.current.getEditor().insertEmbed(null, "image", url);
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ size: [] }],
          [{ font: [] }],
          [{ align: ["right", "center", "justify"] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
          [{ color: [] }],
          [{ background: [] }],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];

  return (
    <div>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        modules={modules}
        formats={formats}
        value={value}
        onChange={handleProcedureContentChange}
      />
    </div>
  );
};
