import { alertService } from "./alertService";

export const ALERT_SUCCESS = "ALERT_SUCCESS";
export const ALERT_ERROR = "ALERT_ERROR";
export const ALERT_PROGRESS = "ALERT_PROGRESS";
export const AlertHelper = {
  success,
  error,
  progress,
};
function success(message) {
  alertService.sendMessage(message, "SNACKBAR", ALERT_SUCCESS);
}

function error(message) {
  alertService.sendMessage(message, "SNACKBAR", ALERT_ERROR);
}

function progress(message) {
  alertService.sendMessage(message, "SNACKBAR", ALERT_PROGRESS);
}
