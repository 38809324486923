import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { Colors } from "../../_constants";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: `${Colors.WHITE}`,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  half_dialog: {
    width: "40%",
    marginLeft: "60%",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export function HalfScreenDialog(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.CloseCallback();
  };

  return (
    <div>
      <Dialog
        className={classes.half_dialog}
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} elevation={0}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}></Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container>{props.component}</Container>
      </Dialog>
    </div>
  );
}
