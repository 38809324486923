import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ROUTER_DETAILS,
  ADD_ROUTER,
  DELETE_ROUTER,
  UPDATE_ROUTER,
  GET_NEXT_ROUTER,
} from "../_queries";

export const useRouter = () => {
  const [
    GetRouter,
    { loading: routerloading, data: routerData = { GetRouterDetails: {} } },
  ] = useLazyQuery(GET_ROUTER_DETAILS);

  const { GetRouterDetails } = routerData;

  const [
    AddRouterDetails,
    { loading: addRouterloading, data: addRouterData = { AddRouter: [] } },
  ] = useMutation(ADD_ROUTER);

  const { AddRouter } = addRouterData;

  const [
    UpdateRouterDetails,
    {
      loading: updateRouterloading,
      data: updateRouterData = { UpdateRouter: [] },
    },
  ] = useMutation(UPDATE_ROUTER);

  const { UpdateRouter } = updateRouterData;

  const [
    DeleteRouterDetails,
    {
      loading: deleteRouterloading,
      data: deleteRouterData = { DeleteRouter: [] },
    },
  ] = useMutation(DELETE_ROUTER);

  const { DeleteRouter } = deleteRouterData;

  return {
    GetRouter,
    routerloading,
    GetRouterDetails,
    GET_ROUTER_DETAILS,
    AddRouterDetails,
    addRouterloading,
    AddRouter,
    DeleteRouterDetails,
    UpdateRouterDetails,
  };
};
