import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export function AlertDialog(props) {
    const [open, setOpen] = React.useState(props.open);

    const handlePositive = () => {
        setOpen(false);
        props.positive()
    };

    const handleNegative = () => {
        setOpen(false);
        props.negative()
    };

    return (
        <div>

            <Dialog
                open={open}
                onClose={handleNegative}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNegative} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handlePositive} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
