import {
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableContainer,
    makeStyles,
  } from "@material-ui/core";
  import AddBoxIcon from "@material-ui/icons/AddBox";
  import { useEffect, useState } from "react";
  import { AlertDialog, HalfScreenDialog } from "../../";
  import { useConfig, useGlobalState } from "../../../hooks";
  import CloseIcon from "@material-ui/icons/Close";
  import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
  import { Colors } from "../../../_constants";
  import { UpdateConfig } from "./UpdateConfig";
import { useQuery } from "@apollo/client";
import { GET_ALL_CONFIG } from "../../../_queries";
import { AlertHelper, LoaderHelper } from "../../../_helpers";
import { ProcessGrapqlError } from "../../../";
  
  const useStyles = makeStyles((theme) => ({
    container: {
      marginTop: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
      "& > *": {
        marginRight: theme.spacing(1),
      },
    },
    table: {
      minWidth: 650,
    },
    bredcrumb: {
      backgroundColor: `${Colors.WHITE}`,
      marginBottom: "15px",
      borderRadius: "5px",
    },
    bredcrumbTitle: {
      fontSize: "16px",
      fontWeight: "600",
    },
    tableHeader: {
      backgroundColor: `${Colors.SECONDARY_LIGHT}`,
      padding: "15px",
    },
  }));
  export const ConfigList = ({ config, name }) => {
    const classes = useStyles();
    const { state, setGlobalState } = useGlobalState();
   
    const {Configs,GetConfigs,DeleteConfig,loading} = useConfig(
      config,
      ()=>{}
    )

     const [deleting, SetDeleting] = useState(false);
     const [ref_id, SetRef] = useState("");
  
    function negative() {
      SetDeleting(false);
    }
    function positive() {
      DeleteConfig({ variables: { configId: ref_id } });
    }
   useEffect(() => {
      GetConfigs({
        variables: {
          config: config,
        },
      });
    }, [config]);
  
    function Edit(row) {
      setGlobalState({
        ...state,
        edit_config: true,
        add_config: true,
        data_config: row,
      });
    }
    function Delete(id) {
      SetRef(id);
      SetDeleting(true);
    }
    if (loading) {
      return <></>;
    }
    return (
      <>

        {deleting && (
          <AlertDialog
            open={true}
            negative={negative}
            positive={positive}
            title={"Warning"}
            description={"Are you sure you want to delete?"}
          />
        )}
        {state.add_config && (
          <HalfScreenDialog
            open={true}
            CloseCallback={() => {
              setGlobalState({ ...state, add_config: false });
            }}
            component={<UpdateConfig config={config} />}
          />
        )}
       <List className={classes.bredcrumb}>
          <ListItem>
            <ListItemText
              primary={<span className={classes.bredcrumbTitle}>{name}</span>}
              secondary={`Add ${name} here`}
            />
            <ListItemSecondaryAction>
              <Button
                startIcon={<AddBoxIcon />}
                onClick={() => {
                  setGlobalState({
                    ...state,
                    add_config: true,
                    edit_config: false,
                  });
                }}
                variant={"text"}
                color={"primary"}
              >
                Add New
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
         <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell component="th">Name</TableCell>
                <TableCell component="th">Code</TableCell>
                <TableCell component="th">Value</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Configs &&
                Configs.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell component="td" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell component="td">{row.code}</TableCell>
                    <TableCell component="td">{row.value}</TableCell>
                    <TableCell component="td" align="right">
                      <IconButton onClick={() => Edit(row)} aria-label="delete">
                        <EditOutlinedIcon />
                      </IconButton>
  
                      <IconButton
                        onClick={() => Delete(row.config_id)}
                        aria-label="delete"
                      >
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </> 
    );
  };
  