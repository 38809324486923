import {
  Avatar,
  Box,
  Button,
  Container,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { CustomTextField } from "../_components";
import * as Yup from "yup";
import { Formik } from "formik";
import { AUTH_STAFF } from "../_queries/staff";
import { useMutation } from "@apollo/client";
import { ProcessGrapqlError } from "..";
import { AlertHelper, storageHelper } from "../_helpers";
import { URLS } from "../_constants";
import logo from "../assets/images/favicon.jpeg";
const useStyles = makeStyles((theme) => ({
  paperPadding: {
    padding: "16px",
  },
  root: {
    background: "#aaa",
    display: "flex",
    height: "100vh",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  textBoxAlign: {
    marginBottom: "20px",
  },
  logo: {
    width: "120px",
    borderRadius: "12px",
  },
  logoWrapper: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
  },
}));

export const Login = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [SignIn] = useMutation(AUTH_STAFF, {
    onCompleted(data) {
      const { SignIn: { token = "" } = {} } = data;
      storageHelper.Set("auth", true);
      storageHelper.Set("token", token);
      navigate("/app/" + URLS.ROLE, { replace: true });
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
  });
  return (
    <div className={classes.root}>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Container maxWidth="xs">
        <Paper className={classes.paperPadding}>
          <div className={classes.logoWrapper}>
            {/* <img
              src={logo}
              className={classes.logo}
            /> */}
          </div>

          <Box sx={{ mb: 4 }}>
            <Typography color="textPrimary" variant="h2">
              Sign in
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              Sign in on the internal platform
            </Typography>
          </Box>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .max(255)
                .email("Enter a valid email")
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}
            onSubmit={(payload) => {
              SignIn({ variables: payload });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <CustomTextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  placeholder="Enter your registered email"
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="dense"
                  name="email"
                  className={classes.textBoxAlign}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <CustomTextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  placeholder="Enter your password"
                  label="Password"
                  margin="dense"
                  name="password"
                  type="password"
                  className={classes.textBoxAlign}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Paper>
      </Container>
    </div>
  );
};
