import { gql } from "@apollo/client";
export const GET_USER_LIST = gql`
  query GetUsersList($perPage: Int, $pageNo: Int) {
    GetUsersList(per_page: $perPage, page_no: $pageNo) {
     userlist{
      username
      user_id
      created_on
      email
      country
     } 
     count
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($user_id: String!, $username: String!, $email: String!) {
    UpdateUser(user_id: $user_id, username: $username, email: $email) {
      username
      user_id
      created_on
      email
    }
  }
`;
