import { ListItemIcon, ListItemText, ListItem } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  GitBranch,
  Users,
  Tool,
  Settings,
  User,
  LogOut,
  ShoppingBag,
  DollarSign,
  UserX,
  Feather,
  Link
} from "react-feather";
import { useNavigate } from "react-router-dom";
import { Colors, URLS } from "../../_constants";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    marginTop: "64px",
    width: drawerWidth,
    backgroundColor: `${Colors.PRIMARY}`,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  customListItem: {
    fontSize: "0.875rem",
    color: `${Colors.WHITE}`,
    "& :hover": {
      background: `${Colors.PRIMARY_DARK}`,
      cursor: "pointer",
    },
  },
  iconColor: {
    color: `${Colors.WHITE}`,
  },
}));

const items = [
  {
    href: "/app/" + URLS.ROLE,
    icon: GitBranch,
    title: "Role",
    status: true,
  },
  {
    href: "/app/" + URLS.STAFF,
    icon: User,
    title: "Staff",
    status: true,
  },
  {
    href: "/app/" + URLS.PROFILE,
    icon: User,
    title: "Profile Setup",
    status: true,
  },
  {
    href: "/app/" + URLS.SETTINGS,
    icon: Settings,
    title: "Settings",
    status: true,
  },
  {
    href: "/app/" + URLS.SURVEY,
    icon: Tool,
    title: "Survey",
    status: true,
  },
  {
    href: "/app/" + URLS.USERS,
    icon: UserX,
    title: "Users",
    status: true,
  },
  {
    href: "/app/" + URLS.SWEEPSTACK,
    icon: DollarSign,
    title: "Sweepstack",
    status: true,
  },
  {
    href: "/app/" + URLS.BLOG,
    icon: Feather,
    title: "Blog",
    status: true,
  },
  {
    href: "/app/" + URLS.ROUTER,
    icon: Link,
    title: "Router",
    status: true,
  },
  {
    href: "/login",
    icon: LogOut,
    title: "Logout",
    status: true,
  },
];

export function DashboardSidebar(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const content = items.map((item, idx) => {
    if (item.status) {
      return (
        <div className={classes.customListItem}>
          <ListItem
            key={item.title}
            onClick={() => {
              navigate(item.href, { replace: true });
            }}
          >
            <ListItemIcon
              className={classes.iconColor}
              style={{ marginRight: "-25px" }}
            >
              <item.icon size={15} />
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        </div>
      );
    }
  });

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={true}
      classes={{ paper: classes.drawerPaper }}
    >
      <List className={classes.drawer_list}>{content}</List>
    </Drawer>
  );
}
