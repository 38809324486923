const S3_BUCKET = "elspurstorage";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIAJWESKPIPIJGD2DHQ";
const SECRET_ACCESS_KEY = "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";
const DIRNAME = "media";
const URL="https://elspurstorage.s3.amazonaws.com"


export const config = {
  bucketName: S3_BUCKET,
  dirName: DIRNAME,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  url:URL
};