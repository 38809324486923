import { gql } from "@apollo/client";

export const AUTH_STAFF = gql`
  mutation SignIn($email: String!, $password: String!) {
    SignIn(email: $email, password: $password) {
      token
    }
  }
`;
export const GET_STAFFS = gql`
  query {
    Staffs: GetStaffs {
      user_id
      fullname
      email
      mobile
      role_id
      role {
        role_name
      }
    }
  }
`;

export const ADD_STAFF = gql`
  mutation AddStaff(
    $fullname: String!
    $email: String!
    $mobile: String!
    $role_id: String!
  ) {
    AddStaff(
      fullname: $fullname
      email: $email
      mobile: $mobile
      role_id: $role_id
    ) {
      user_id
      fullname
      email
      role_id
      role {
        role_name
      }
    }
  }
`;

export const UPDATE_STAFF = gql`
  mutation UpdateStaff(
    $fullname: String!
    $mobile: String!
    $role_id: String!
    $user_id: String!
  ) {
    UpdateStaff(
      fullname: $fullname
      mobile: $mobile
      role_id: $role_id
      user_id: $user_id
    ) {
      user_id
      fullname
      email
      mobile
      role_id
      role {
        role_name
      }
    }
  }
`;

export const DELETE_STAFF = gql`
  mutation DeleteStaff($user_id: String!) {
    DeleteStaff(user_id: $user_id) {
      user_id
    }
  }
`;
