import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";
import { CustomTextField } from "..";
import { ProcessGrapqlError } from "../..";
import { Context } from "../../store";
import { AlertHelper } from "../../_helpers";
import {
  ADD_STAFF,
  GET_USER_LIST,
  UPDATE_USER,
} from "../../_queries";
import { useUser } from "../../hooks";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export const UpdateUsers = () => {
  const classes = useStyles();
  const { state, setGlobalState } = useContext(Context);
  const [AddStaff] = useMutation(ADD_STAFF, {
    onCompleted() {
      AlertHelper.success("Successfully added!");
      reset();
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddStaff } }) {
      const existing = cache.readQuery({ query: GET_USER_LIST });
      const newData = [...existing.Staffs, AddStaff];
      cache.writeQuery({
        query: GET_USER_LIST,
        data: { Staffs: newData },
      });
    },
  });


  const [UpdateUser] = useMutation(UPDATE_USER, {
    onCompleted() {
      AlertHelper.success("Successfully updated!");
      reset();
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateUser } }) {
      const existing = cache.readQuery({ query: GET_USER_LIST });
      const newData = existing.Staffs.map((item) =>
        item.user_id !== UpdateUser.user_id ? item : UpdateUser
      );
      cache.writeQuery({
        query: GET_USER_LIST,
        data: { Staffs: newData },
      });
    },
  });
  const { editing_staff: editing = false, edit_staff: editData = {} } = state;
  function reset() {
    setGlobalState({
      ...state,
      edit_staff: {},
      editing_staff: false,
      staff_adding: false,
    });
  }
  return (
    <Formik
      initialValues={{
        username: `${editing ? editData.username : ""}`,
        email: `${editing ? editData.email : ""}`,
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().max(255).required("Name is required"),
        email: Yup.string()
          .max(255)
          .email("Enter a valid email")
          .required("Email is required"),
      })}
      onSubmit={(payload) => {
        if (editing) {
          AlertHelper.progress("Updating...");
          payload["user_id"] = editData.user_id;
          UpdateUser({ variables: payload });
        } else {
          AlertHelper.progress("Creating...");
          AddStaff({ variables: payload });
        }
        reset();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography color="textPrimary" variant="h2">
              Create new User
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              All fields are mandetory
            </Typography>
          </Box>
          <Grid container spacing={3} direction="row">
            <Grid item md={6}>
              <CustomTextField
                error={Boolean(touched.username && errors.username)}
                fullWidth
                helperText={touched.username && errors.username}
                label="User name"
                margin="dense"
                placeholder="Enter User name"
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                // disabled={editing}
                helperText={touched.email && errors.email}
                label="Email"
                margin="dense"
                placeholder="Enter user email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Box className={classes.button}>
            <Button
              onClick={() => {
                reset();
              }}
              variant="contained"
              color="default"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
                {
                  editing ? "Update" : "Save"
                }
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
