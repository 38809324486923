import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AlertHelper } from "../_helpers";
import {
  ADD_CONFIG,
  DELETE_CONFIG,
  GET_CONFIG,
  GET_CONFIGS,
  UPDATE_CONFIG,
} from "../_queries";

export const useConfig = (config, callback = () => {}) => {

  const [AddConfig] = useMutation(ADD_CONFIG, {
    onCompleted(res) {
      AlertHelper.success("Successfully created");
      callback("add", res.AddConfig);
    },
    update(cache, { data: { AddConfig } }) {
      const existing = cache.readQuery({
        query: GET_CONFIGS,
        variables: {
          config: config,
        },
      });
      const newData = [...existing.GetConfig, AddConfig];

      cache.writeQuery({
        query: GET_CONFIGS,
        data: { GetConfig: newData },
        variables: {
          config: config,
        },
      });
    },
  });

  const [UpdateConfig] = useMutation(UPDATE_CONFIG, {
    onCompleted(res) {
      AlertHelper.success("Successfully updated");
      callback("update", res.UpdateConfig);
    },
    update(cache, { data: { UpdateConfig } }) {
      const existing = cache.readQuery({
        query: GET_CONFIGS,
        variables: {
            config: config,
          },
      });
      const newData = existing.GetConfig.map((item) =>
        item.config_id !== UpdateConfig.config_id ? item : UpdateConfig
      );

      cache.writeQuery({
        query: GET_CONFIGS,
        data: { GetConfig: newData },
        variables: {
          config: config,
        },
      });
    },
  });
  const [DeleteConfig] = useMutation(DELETE_CONFIG, {
    onCompleted(res) {
      AlertHelper.success("Successfully Deleted");
      callback("delete", res.DeleteConfig);
    },
    update(cache, { data: { DeleteConfig } }) {
      const existing = cache.readQuery({
        query: GET_CONFIGS,
        variables: {
          config: config,
        },
      });
      const newData = existing.GetConfig.filter(
        (item) => item.config_id !== DeleteConfig.config_id
      );
      cache.writeQuery({
        query: GET_CONFIGS,
        data: { GetConfig: newData },
        variables: {
          config: config,
        },
      });
    },
  });

  const [GetConfigs, { loading, data = { GetConfig: [] } }] =
    useLazyQuery(GET_CONFIGS);
  const { GetConfig } = data;



  return {
    GetConfigs,
    Configs: GetConfig,
    loading,
    AddConfig,
    UpdateConfig,
    DeleteConfig,
  };
};
