import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Typography,
  MenuItem,
} from "@material-ui/core";
import { Formik } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";
import { CustomTextField, CustomSelectField } from "..";
import { ProcessGrapqlError } from "../..";
import { Context } from "../../store";
import { AlertHelper } from "../../_helpers";
import {
  ADD_PROFILE_STAGE,
  GET_STAGES,
  UPDATE_PROFILE_STAGE,
} from "../../_queries";
import Country from "../../_json/countries.json";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export const UpdateProfileStage = () => {
  const classes = useStyles();
  const { state, setGlobalState } = useContext(Context);
  const [AddProfileStage] = useMutation(ADD_PROFILE_STAGE, {
    onCompleted() {
      AlertHelper.success("Successfully added!");
      reset();
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddProfileStage } }) {
      const existing = cache.readQuery({ query: GET_STAGES });
      const newData = [...existing.GetProfileStages, AddProfileStage];
      cache.writeQuery({
        query: GET_STAGES,
        data: { GetProfileStages: newData },
      });
    },
  });

  const [UpdateProfileStage] = useMutation(UPDATE_PROFILE_STAGE, {
    onCompleted() {
      AlertHelper.success("Successfully updated!");
      reset();
    },
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateProfileStage } }) {
      const existing = cache.readQuery({ query: GET_STAGES });
      const newData = existing.GetProfileStages.map((item) =>
        item.stage_id !== UpdateProfileStage.stage_id
          ? item
          : UpdateProfileStage
      );
      cache.writeQuery({
        query: GET_STAGES,
        data: { GetProfileStages: newData },
      });
    },
  });
  const { editing_stage: editing = false, edit_stage: editData = {} } = state;
  function reset() {
    setGlobalState({
      ...state,
      edit_stage: {},
      editing_stage: false,
      stage_adding: false,
    });
  }
  return (
    <Formik
      initialValues={{
        stage_name: `${editing ? editData.stage_name : ""}`,
        order: editing ? editData.order : 1,
        thumb_url: editing ? editData.thumb_url : "",
        length: editing ? editData.length : "",
        chip: editing ? editData.chip : "",
        coin: editing ? editData.coin : "",
        country: editing ? editData.country : "",
      }}
      validationSchema={Yup.object().shape({
        stage_name: Yup.string().max(255).required("Stage name is required"),
        order: Yup.number()
          .min(1)
          .max(100)
          .required("Order number is required for sorting"),
        thumb_url: Yup.string().required("URL is required"),
        length: Yup.string().required("Length is required"),
        chip: Yup.string().required("Chip is required"),
        coin: Yup.string().required("Coin is required"),
        country: Yup.string().required("Country is required"),
      })}
      onSubmit={(payload) => {
        if (editing) {
          AlertHelper.progress("Updating...");
          payload["stage_id"] = editData.stage_id;
          UpdateProfileStage({ variables: payload });
        } else {
          console.log(payload);
          AlertHelper.progress("Creating...");
          AddProfileStage({ variables: payload });
        }
        reset();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Typography color="textPrimary" variant="h2">
              {editing ? "Update Stage" : "Create new Stage"}
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body2">
              All fields are mandetory
            </Typography>
          </Box>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item md={12}>
              <CustomTextField
                error={Boolean(touched.stage_name && errors.stage_name)}
                fullWidth
                helperText={touched.stage_name && errors.stage_name}
                label="Stage Name"
                margin="dense"
                placeholder="Enter stage name"
                name="stage_name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.stage_name}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12}>
              <CustomTextField
                error={Boolean(touched.order && errors.order)}
                fullWidth
                helperText={touched.order && errors.order}
                label="Order"
                margin="dense"
                placeholder="Enter order for sort"
                name="order"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.order}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12}>
              <CustomTextField
                error={Boolean(touched.thumb_url && errors.thumb_url)}
                fullWidth
                helperText={touched.thumb_url && errors.thumb_url}
                label="Thumbnail Url"
                margin="dense"
                placeholder="Enter image url"
                name="thumb_url"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.thumb_url}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12}>
              <CustomTextField
                error={Boolean(touched.length && errors.length)}
                fullWidth
                helperText={touched.length && errors.length}
                label="Survey Length"
                margin="dense"
                placeholder="Survey length"
                name="length"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.length}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                error={Boolean(touched.coin && errors.coin)}
                fullWidth
                helperText={touched.coin && errors.coin}
                label="Coin"
                margin="dense"
                placeholder="Coin"
                name="coin"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.coin}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={6}>
              <CustomTextField
                error={Boolean(touched.chip && errors.chip)}
                fullWidth
                helperText={touched.chip && errors.chip}
                label="Chip"
                margin="dense"
                placeholder="Chip"
                name="chip"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.chip}
                variant="standard"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item md={12}>
              <CustomSelectField
                select
                error={Boolean(touched.country && errors.country)}
                fullWidth
                helperText={touched.country && errors.country}
                label="Country"
                margin="dense"
                name="country"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.country}
              >
                {Country.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </CustomSelectField>
            </Grid>
          </Grid>
          <Box className={classes.button}>
            <Button
              onClick={() => {
                reset();
              }}
              variant="contained"
              color="default"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
