import { Grid, List, ListItem, makeStyles, Table } from "@material-ui/core";
import { useState } from "react";
import { ConfigList } from "../_components";
import { Colors } from "../_constants";

const useStyles = makeStyles({
  cus_list_item: {
    paddingTop: "8px",
    paddingBottom: "8px",
    fontSize: "0.875rem",
  },
  active: {
    borderLeft: `5px solid ${Colors.PRIMARY}`,
    color: `${Colors.PRIMARY}`,
  },
  cus_menu_list_icon: {
    minWidth: "45px",
  },
  table: {
    minWidth: 650,
  },
});

const config = [
  { name: "Colors", code: "Colors" },
  { name: "Cars", code: "Cars" },
];

export const Configs = () => {
  const classes = useStyles();
  const [active_id, SetActive] = useState(0);
  const [currentItem, SetItem] = useState(config[0]);
  function OpenConfig(idx, item) {
    SetActive(idx);
    SetItem(item);
  }

  return (
    <Grid container spacing={1}>
      <Grid item md={2}>
        <List>
          {config.map((row, idx) => (
            <ListItem
              className={[
                classes.cus_list_item,
                idx == active_id ? classes.active : "",
              ]}
              onClick={() => {
                OpenConfig(idx, row);
              }}
              button
              key={idx}
            >
              {row.name}
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item md={10}>
        {currentItem?.code && (
          <ConfigList config={currentItem.code} name={currentItem.name} />
        )}
      </Grid>
    </Grid>
  );
};
