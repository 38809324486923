import React, { useState } from 'react'
import { CustomTextField } from './CustomTextField'

export const CustomAgeRange = ({ onChange, name, value }) => {
  const [range, SetRange] = useState({
    min: value?.min ?? 0,
    max: value?.max ?? 0
  })
  const { min, max } = range
  const handleChange = (e) => {
    const age = { ...range, [e.target.name]: e.target.value }
    SetRange(age)
    onChange({
      type: 'input',
      target: {
        value: age,
        id: name,
        name: name
      }
    })
  }

  return (
    <React.Fragment>
      <CustomTextField
        onChange={handleChange}
        InputLabelProps={{
          shrink: true
        }}
        value={min}
        name='min'
        label={'Age Min'}
        type={'number'}
      />
      <CustomTextField
        onChange={handleChange}
        value={max}
        InputLabelProps={{
          shrink: true
        }}
        name='max'
        type={'number'}
        label={'Age Max'}
      />
    </React.Fragment>
  )
}
