import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { alertService } from "../../_helpers";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export function CustomFullLoader() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  alertService.getMessage().subscribe((response) => {
    if (response.type === "LOAD" && response.status === "PROGRESS") {
      setOpen(true);
    }
    if (response.type === "LOAD" && response.status === "CLOSE") {
      setOpen(false);
    }
  });
  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
