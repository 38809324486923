import { gql } from "@apollo/client";
import * as Yup from "yup";
import { CustomMultiCheckbox } from "../_components/global/CustomMultiCheckbox";
import { CustomRadioButton } from "../_components/global/CustomRadioButton";
import { CustomTextArea } from "../_components/global/CustomTextArea";
import { CustomTextField } from "../_components/global/CustomTextField";
import { Checkbox } from "@mui/material";
import { CustomAgeRange } from "../_components/global/CustomAgeRange";
export const ComponentHelper = {
  GetComponent,
  GetInitialValue,
  GetValidationScheme,
  GetSubmitVariables,
  GetMutationQueryFromTemplate,
  GetQueryFromTemplate,

  GetActionComponent,
};

const DataType = {
  text: { mongo: "String", graph: "String" },
  json: { mongo: "Object", graph: "JSON" },
  radio: { mongo: "String", graph: "String" },
  datetime: { mongo: "Date", graph: "String" },
  checkbox: { mongo: "Boolean", graph: "Boolean" },
  time: { mongo: "Date", graph: "String" },
  file: { mongo: "String", graph: "String" },
  number: { mongo: "Number", graph: "Int" },
  tel: { mongo: "String", graph: "String" },
  url: { mongo: "String", graph: "String" },
  textarea: { mongo: "String", graph: "String" },
  email: { mongo: "String", graph: "String" },
  day: { mongo: "String", graph: "String" },
};

const TYPE = {
  radio: "radio",
  select: "select",
  textfield: "textfield",
  button: "button",
  datetime: "datetime",
  checkbox: "checkbox",
  time: "time",
  file: "file",
  number: "number",
  phoneNumber: "phoneNumber",
  url: "url",
  textarea: "textarea",
  email: "email",
};
function GetInitialValue(component, isEdit, editData, type = "") {
  let initialValue = {};
  // template.map((item) => {
  // const { properties = {} } = item;

  const { properties = {} } = component;

  let flag = false;
  if (type === "") {
    flag = true;
  } else if (properties[type] === "true") {
    flag = true;
  }

  if (flag) {
    const {
      inputType,
      key,
      autocomplete = "",
      type,
      multiple = false,
    } = component; //item;
    const typ = inputType ? inputType : type;
    if (type !== "button") {
      switch (typ) {
        case "radio":
          initialValue[key] = isEdit
            ? editData[key]
              ? editData[key]
              : ""
            : "";
          break;
        case "select":
          if (multiple) {
            initialValue[key] = isEdit
              ? editData[key]
                ? editData[key]
                : []
              : [];
          } else {
            initialValue[key] = isEdit
              ? editData[key]
                ? editData[key]
                : ""
              : "";
          }

          break;
        case "text":
          if (autocomplete.trim() !== "") {
            let data = { id: "", name: "" };
            try {
              if (editData[key] && editData[key] !== "") {
                const obj = JSON.parse(editData[key]);
                data = obj;
              }
            } catch (e) {}

            initialValue[key] = isEdit ? data : { id: "", name: "" };
          } else {
            let data = "";
            if (editData[key]) {
              data = editData[key];
            }
            initialValue[key] = isEdit ? data : "";
          }

          break;
        case "email":
          initialValue[key] = isEdit
            ? editData[key]
              ? editData[key]
              : ""
            : "";
          break;

        case TYPE.url:
          initialValue[key] = isEdit
            ? editData[key]
              ? editData[key]
              : ""
            : "";
          break;

        case TYPE.time:
          initialValue[key] = isEdit
            ? editData[key]
              ? editData[key]
              : ""
            : "";
          break;
        case TYPE.number:
          initialValue[key] = isEdit
            ? editData[key]
              ? editData[key]
              : ""
            : "";
          break;
        default:
          initialValue[key] = isEdit
            ? editData[key]
              ? editData[key]
              : ""
            : "";
          break;
      }
    }
  }
  // });
  return initialValue;
}

function GetValidationScheme(template, type = "") {
  let yupObj = {};
  template.map((item) => {
    const { properties = {} } = item;
    let flag = false;
    if (type === "") {
      flag = true;
    } else if (properties[type] === "true") {
      flag = true;
    }
    if (flag) {
      const {
        inputType,
        type,
        key,
        validate,
        errorLabel,
        autocomplete = "",
        multiple = false,
      } = item;
      const { required, minLength = 0, maxLength = 255 } = validate;

      let _minLength = 0;
      let _maxLength = 255;
      if (minLength !== "") {
        _minLength = minLength;
      }
      if (maxLength !== "") {
        _maxLength = 255;
      }
      const typ = inputType ? inputType : type;
      if (required) {
        if (type !== "button") {
          switch (typ) {
            case "radio":
              yupObj[key] = Yup.string().required(errorLabel);
              break;
            case "select":
              if (multiple) {
                yupObj[key] = Yup.array().required(errorLabel);
              } else {
                yupObj[key] = Yup.string().required(errorLabel);
              }

              break;
            case "text":
              if (autocomplete.trim() !== "") {
                yupObj[key] = Yup.object().shape({
                  id: Yup.string().required(errorLabel),
                });
              } else {
                yupObj[key] = Yup.string()
                  .min(_minLength)
                  .max(_maxLength)
                  .required(errorLabel);
              }

              break;
            case "email":
              yupObj[key] = Yup.string()
                .min(_minLength)
                .max(_maxLength)
                .email("Please enter a valid email")
                .required(errorLabel);
              break;

            case "url":
              const regMatch =
                /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

              yupObj[key] = Yup.string()
                .min(_minLength)
                .max(_maxLength)
                .matches(regMatch, "URL is not valid")
                .required(errorLabel);
              break;
            default:
              yupObj[key] = Yup.string().required(errorLabel);
              break;
          }
        }
      }
    }
  });
  return Yup.object().shape(yupObj);
}
function GetSubmitVariables(template, payload, extra = {}, type = "") {
  let result = extra;
  template.map((item) => {
    const { properties = {} } = item;
    let flag = false;
    if (type !== "button") {
      if (type === "") {
        flag = true;
      } else if (properties[type] === "true") {
        flag = true;
      }
      if (flag) {
        // const { key, inputType, autocomplete } = item;
        // const data = payload[key];
        // let variable = "";
        // if (inputType === "text" && autocomplete !== "") {
        //   variable = JSON.stringify(data);
        // } else {
        //   variable = data;
        // }
        result[item.key] = payload[item.key];
      }
    }
  });

  return result;
}
function GetMutationQueryFromTemplate(
  template,
  mutationName,
  isUpdate = false,
  type = "",
  param1 = "",
  param2 = ""
) {
  const schema = GetSchemaFromTemplate(template);

  const query = gql`mutation SubmitData(
            ${schema.map((sch, idx) => {
              const { properties = {} } = sch;
              let flag = false;
              if (type === "") {
                flag = true;
              } else if (properties[type] === "true") {
                flag = true;
              }
              if (flag) {
                return `${idx > 0 ? "," : ""}${"$" + sch.key}:${sch.type.name}${
                  sch.type.required ? "!" : ""
                }`;
              }
            })}${isUpdate ? ",$id: String!" : ""}${
    param1 === "" ? "" : "," + param1
  })
          {
            mutationData: ${mutationName}(
              ${schema.map((sch, idx) => {
                const { properties = {} } = sch;
                let flag = false;
                if (type === "") {
                  flag = true;
                } else if (properties[type] === "true") {
                  flag = true;
                }
                if (flag) {
                  return `${idx > 0 ? "," : ""}${sch.key}:${"$" + sch.key}`;
                }
              })}${isUpdate ? ",id: $id" : ""}${
    param2 === "" ? "" : "," + param2
  }
            ){
              ok,
              error,
              payload{
                ${schema.map((sch, idx) => `${idx > 0 ? "," : ""}${sch.key}`)}
              }
            }
          }
          `;

  return query;
}

function GetQueryFromTemplate(template, queryName, params, extra = "") {
  const schema = GetSchemaFromTemplate(template);
  var param = "";
  var query_param = "";
  for (const [key, value] of Object.entries(params)) {
    if (param !== "") {
      param = param + ",$" + key + ":" + value;
      query_param = query_param + "," + key + " : $" + key;
    } else {
      param = "$" + key + ":" + value;
      query_param = key + " : $" + key;
    }
  }
  const query = gql`query GetQuery(${param})
          {
            ${queryName}: ${queryName}(
                ${query_param}
            ){
                ${schema.map(
                  (sch, idx) => `${idx > 0 ? "," : "id," + extra}${sch.key}`
                )}
            }
          }
          `;

  return query;
}

function GetSchemaFromTemplate(template, type = "") {
  let schema = [];
  template.map((item) => {
    const { properties = {}, autocomplete = "" } = item;
    let flag = false;
    if (type === "") {
      flag = true;
    } else if (properties[type] === "true") {
      flag = true;
    }
    if (flag) {
      const { inputType = "blank", key, validate, type, properties } = item;
      let typeCheck = inputType;
      if (type !== "button") {
        let Keytype = {};
        if (typeCheck === "blank") {
          typeCheck = type;
        }
        if (inputType === "text" && autocomplete !== "") {
          typeCheck = "json";
        }
        Keytype["name"] = DataType[typeCheck].graph;
        Keytype["required"] = validate.required;
        Keytype["mongo-type"] = DataType[typeCheck].mongo;

        let row = {};
        row["key"] = key;
        row["properties"] = properties;
        row["type"] = Keytype;
        schema.push(row);
      }
    }
  });
  return schema;
}

function GetComponent(props) {
  const {
    component,
    touched,
    errors,
    values,
    onBlur,
    onChange,
    key: propkey,
  } = props;
  const {
    label,
    hidelabel = false,
    placeholder,
    autocomplete = "",
    tooltip,
    disabled,
    type,
    key,
    validate,
    errorLabel,
    inputType,
    calculateValue = false,
    values: options = [],
    data = { values: [] },
    multiple = false,
    filePattern = "",
    fileMaxSize = "100",
    rows = 3,
  } = component;

  const { values: selectoptions } = data;

  if (type !== "button") {
    switch (type) {
      case "radio":
        return (
          <CustomMultiCheckbox
            key={propkey}
            multiple={true}
            hidelabel={"true"}
            error={Boolean(touched[key] && errors[key])}
            helperText={touched[key] && errors[key]}
            label={key}
            required={validate.required}
            // placeholder={placeholder}
            name={key}
            onBlur={onBlur}
            onChange={onChange}
            options={options}
            value={values[key]}
          />
        );
      case "textfield":
        return (
          <CustomTextField
            key={propkey}
            hidelabel={"true"}
            error={Boolean(touched[key] && errors[key])}
            helperText={touched[key] && errors[key]}
            label={key}
            required={validate.required}
            placeholder={placeholder}
            name={key}
            onBlur={onBlur}
            onChange={onChange}
            value={values[key]}
          />
        );
      case "datetime":
        if (key === "AGE") {
          return (
            <CustomAgeRange
              key={propkey}
              name={key}
              onChange={onChange}
              value={values[key]}
            />
          );
        } else
          return (
            <CustomTextField
              key={propkey}
              hidelabel={"true"}
              error={Boolean(touched[key] && errors[key])}
              helperText={touched[key] && errors[key]}
              // label={label}
              required={validate.required}
              // placeholder={placeholder}
              name={key}
              onBlur={onBlur}
              onChange={onChange}
              type={"date"}
              value={values[key]}
            />
          );
      case "select":
        return (
          <CustomMultiCheckbox
            key={propkey}
            multiple={multiple}
            hidelabel={"true"}
            error={Boolean(touched[key] && errors[key])}
            helperText={touched[key] && errors[key]}
            label={key}
            required={validate.required}
            // placeholder={placeholder}
            name={key}
            onBlur={onBlur}
            onChange={onChange}
            options={selectoptions}
            value={values[key]}
          />
        );
      case "checkbox":
        return <Checkbox />;
      case "time":
        return (
          <CustomTextField
            key={propkey}
            hidelabel={"true"}
            error={Boolean(touched[key] && errors[key])}
            helperText={touched[key] && errors[key]}
            label={key}
            required={validate.required}
            placeholder={placeholder}
            name={key}
            onBlur={onBlur}
            onChange={onChange}
            type={"time"}
            value={values[key]}
          />
        );

      case "number":
        return (
          <CustomTextField
            key={propkey}
            hidelabel={"true"}
            error={Boolean(touched[key] && errors[key])}
            helperText={touched[key] && errors[key]}
            label={label}
            required={validate.required}
            placeholder={placeholder}
            name={key}
            onBlur={onBlur}
            onChange={onChange}
            type={"number"}
            value={values[key]}
          />
        );

      case "url":
        return (
          <CustomTextField
            key={propkey}
            hidelabel={"true"}
            error={Boolean(touched[key] && errors[key])}
            helperText={touched[key] && errors[key]}
            label={label}
            required={validate.required}
            placeholder={placeholder}
            name={key}
            onBlur={onBlur}
            onChange={onChange}
            type={inputType}
            value={values[key]}
          />
        );
      case "textarea":
        return (
          <CustomTextArea
            key={propkey}
            hidelabel={"true"}
            error={Boolean(touched[key] && errors[key])}
            helperText={touched[key] && errors[key]}
            label={label}
            required={validate.required}
            placeholder={placeholder}
            name={key}
            onBlur={onBlur}
            onChange={onChange}
            type={inputType}
            rows={rows}
            value={values[key]}
          />
        );
      case "email":
        return (
          <CustomTextField
            key={propkey}
            hidelabel={"true"}
            error={Boolean(touched[key] && errors[key])}
            helperText={touched[key] && errors[key]}
            label={label}
            required={validate.required}
            placeholder={placeholder}
            name={key}
            onBlur={onBlur}
            onChange={onChange}
            type={inputType}
            value={values[key]}
          />
        );
      default:
        return <></>;
    }
  }else{
    return <button/>
  }
}

function GetActionComponent(template) {
  let result = {};
  template.map((item) => {
    const { type, event, label } = item;
    if (type === "button") {
      result[event] = label;
    }
  });
  return result;
}
